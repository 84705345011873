// ReporteRecoleccionManifiestos.jsx
import React, { useCallback, useState } from "react";
import { Form, Formik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import schemaReporteRecoleccion from "../../Schemas/SchemaRecoleccionManifiestos";
import CircleSpiner from "../../../../componentes/Spinners/CircleSpiner";
import Accordion from "../../../../componentes/Accordion/Accordion";
import InputTextForm from "./components/InputText";
import { ObtenerClientesManifiestosPorFechaNative, ObtenerManifiestosRecolectaPorRutaFecha } from "../../../../Apis/FetchManifiestos";
import RecoleccionMafiestosTable from "./components/RecoleccionManifiestos.Table";

const ReporteRecoleccionManifiestos = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [columnCustomFilters, setCustomColumnFilters] = useState([]); // Inicializar como array

  const initValues = {
    ruta: '',
    fechaIni: "",
    fechaFin: ""
  };

  const obtenerManifiestos = useCallback(async (clave, fInicio, fFinal) => {
    setLoadingData(true);
    try {
      if (fFinal) {
        console.log('TODO api con fecha inicio y fecha final');
        // Aquí deberías llamar a la API con fecha inicio y fecha final si es necesario
      }
      await ObtenerClientesManifiestosPorFechaNative(clave, fInicio)
      .then(async(responseData) => {
        const dataRecolecta = await ObtenerManifiestosRecolectaPorRutaFecha(clave, fInicio);

        const newData = responseData.map((res, index) => {
          let dataReturn = {
            id: res.manifId,
            fila: index + 1,
            clave: res.clienteClave,
            operador: '',
            unidadNegocioInt: res.clienteUnidadnegocio ?? '',
            ruta: res.manifRuta,
            statusConsultorio: '',
            statusAtencion: '',
            unidadNegocio: '',
            nombre: res.clienteNombre,
            localidad: res.clienteCiudad,
            estado: res.clienteEstado,
            nra: '',
            manifiesto: res.manifClave,
            fechaAtencion: '',
            fvisita: res.manifFvisita,
            sangreBI5: 0, // Número
            patologicoBI3: 0,
            noAnatomicoBI4: 0,
            punzocortanteBI2: 0,
            cultivosCepasBI5: 0,
            liquidoRevelador: 0,
            medicinaCaducada: 0,
            totalResiduos: 0,
            personaQuienEntrega: '',
            noBolsasRojas55x60: 0,
            noContenedoresPunzocortante: 0,
            firmaQuienRecibio: '',
            observaciones: '',
          }
          const daraRec = dataRecolecta.find( dr => dr.manif === res.manifClave)
          if(daraRec){
            dataReturn = {
              ...dataReturn,
              manifiesto : daraRec.manif,
              localidad : daraRec.localidad,
              estado : daraRec.estado,
              nra : daraRec.nra,
              unidadNegocioInt : daraRec.unidad_negocio_interno,
              //fvisita : daraRec.fruta,
              clave : daraRec.cliente,
              operador : daraRec.operador_id,
              unidadNegocio : daraRec.unidad_negocio,
              statusConsultorio : daraRec.status_consultorio,
              statusAtencion : daraRec.status_atencion,
              fvisita : daraRec.fechavisita,
              fechaAtencion : daraRec.fecha_atencion_mes_anterior,
              //cantidad : daraRec.cantidad,
              sangreBI5 : daraRec.sangre_bi5,
              patologicoBI3 : daraRec.patologico_bi3,
              noAnatomicoBI4 : daraRec.no_anatomico_bi4,
              punzocortanteBI2 : daraRec.punzocortante_bi2,
              cultivosCepasBI5 : daraRec.cultivos_cepas_bi5,
              liquidoRevelador : daraRec.liquido_revelador,
              medicinaCaducada : daraRec.medicina_caducada,
              totalResiduos : daraRec.total_residuos,
              noBolsasRojas55x60 : daraRec.bolsas_rojas,
              noContenedoresPunzocortante : daraRec.contenedores_punzocortante,
              personaQuienEntrega : daraRec.persona_entrega,
              firmaQuienRecibio : daraRec.firma_recibe,
              observaciones : daraRec.observaciones,
            }
          }
          return dataReturn
          
        });
        setData(newData);
        // Eliminado: toast.success("Manifiestos obtenidos correctamente");
      })
    } catch (error) {
      console.log("Hubo un problema al obtener los manifiestos"); // Mantener solo toast.error
    } finally {
      setLoadingData(false);
    }
  }, []);

  // Función para manejar el envío manual del formulario
  const buscarManifiestos = async (validateForm, setTouched, resetForm, ruta, fechaIni, fechaFin) => {
    const formErrors = await validateForm();
    if (Object.keys(formErrors).length === 0) {
      // No hay errores, ejecutar obtenerManifiestos
      await obtenerManifiestos(ruta, fechaIni, fechaFin)
        .then(() => {
          // Actualizar initialValues con los valores recibidos para siguientes comparaciones
          resetForm({ values: { fechaFin, fechaIni, ruta } });
        });
    } else {
      setData([]);
      // Marcar todos los campos como tocados para mostrar errores
      setTouched({
        ruta: true,
        fechaIni: true,
        fechaFin: true
      });
      // Mostrar notificación de error
      //toast.error("Por favor, corrige los errores en el formulario.");
    }
  }

  return (
    <>
      {loadingData && <CircleSpiner />}
      <Formik
        initialValues={initValues}
        validationSchema={schemaReporteRecoleccion}
        onSubmit={() => { }} // No se utiliza onSubmit tradicional
      >
        {(props) => {
          const { values, isSubmitting, setFieldValue, validateForm, setTouched, resetForm } = props;

          // Definir getData dentro del renderizado para acceder a los valores actuales
          const getDataFunction = async () => {
            await buscarManifiestos(validateForm, setTouched, resetForm, values.ruta, values.fechaIni, values.fechaFin)
          };

          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos del Viaje"
                idTarget="collapseManifDocsVForm"
                expanded="true"
              >
                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <InputTextForm
                    disabled={isSubmitting || loadingData}
                    label="Clave ruta"
                    name="ruta"
                    type="number"
                    placeholder=""
                    onBlurAction={async () => await buscarManifiestos(validateForm, setTouched, resetForm, values.ruta, values.fechaIni, values.fechaFin)}
                    onKeyDownAction={async () => await buscarManifiestos(validateForm, setTouched, resetForm, values.ruta, values.fechaIni, values.fechaFin)}
                  />
                  <InputTextForm
                    disabled={isSubmitting || loadingData}
                    label="Fecha Inicial"
                    type="date"
                    placeholder=""
                    name="fechaIni"
                    onBlurAction={async () =>
                      setFieldValue("fechaIni", values.fechaIni)
                        .then(async () => await buscarManifiestos(validateForm, setTouched, resetForm, values.ruta, values.fechaIni, values.fechaFin)
                        )
                    }
                    onKeyDownAction={
                      async () => {
                        await buscarManifiestos(validateForm, setTouched, resetForm, values.ruta, values.fechaIni, values.fechaFin)
                      }
                    }
                  />
                  <InputTextForm
                    disabled
                    label="Fecha Final"
                    type="date"
                    placeholder=""
                    name="fechaFin"
                    onBlurAction={async () =>
                      setFieldValue("fechaIni", values.fechaIni)
                        .then(async () => await buscarManifiestos(validateForm, setTouched, resetForm, values.ruta, values.fechaIni, values.fechaFin)
                        )
                    }
                    onKeyDownAction={
                      async () => {
                        await buscarManifiestos(validateForm, setTouched, resetForm, values.ruta, values.fechaIni, values.fechaFin)
                      }
                    } />
                  <button
                    disabled={isSubmitting || loadingData}
                    type="button"
                    onClick={async () => await getDataFunction()}
                    className="btn-secondary m-3"
                  >
                    Buscar Manifiestos
                  </button>
                </div>
              </Accordion>

              <Accordion
                titulo={`Manifiestos Recolectados en la Ruta ${values.ruta ?? ""}`}
                idTarget="collapseClientesManifForm"
                expanded={data.length > 0 ? "true" : "false"}
                disabled={data.length <= 0}
              >
                <div className="flex flex-col w-full">
                  <RecoleccionMafiestosTable
                    data={data}
                    setData={setData}
                    getData={getDataFunction}
                    columnCustomFilters={columnCustomFilters}
                    setCustomColumnFilters={setCustomColumnFilters} // Pasar esta prop
                  />
                </div>
              </Accordion>

              <div className="h-2" />
              <div className="flex items-center space-x-4 m-3">
                <button
                  disabled={isSubmitting || data.length === 0}
                  type="submit" // Asegúrate de que el tipo es "submit"
                  className="btn-primary m-3 md:max-w-72"
                >
                  Grabar
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ReporteRecoleccionManifiestos;
