export function createOption(key, value, label) {
    return (
      <option className="text-base" key={key} value={value}>
        {label}
      </option>
    )
  }

  export function parseNullToEmpty(obj) {
    const newObj = {};
    for (const key in obj) {
      newObj[key] = obj[key] ?? ""; 
    }
    return newObj;
  }

  export const sleep = (ms) => new Promise((r) => setTimeout(r, ms));