import * as Yup from "yup";

const MSJ_DATO_REQ = "Dato requerido";

const schemaReporteRecoleccion = Yup.object({
    ruta: Yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .required(MSJ_DATO_REQ),
    fechaIni: Yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .required('Fecha Inicial es requerida'),
    fechaFin: Yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .when('fechaIni', {
            is: (value) => value !== "",
            then: (schema) => schema
                .min(Yup.ref('fechaIni'), 'Fecha Final debe ser mayor a Fecha Inicial'),
            otherwise: (schema) => schema.nullable(),
        }),
})

export default schemaReporteRecoleccion