import * as Yup from "yup";

const validationFormSchema = Yup.object().shape({
    id: Yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable(),
    ruta: Yup.number()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable(),
    cliente: Yup.string().nullable(),
    fecha: Yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .nullable(),
    familia: Yup.string().nullable(),
    oper: Yup.string().nullable(),
    rangoFechas: Yup.boolean(),
    fechaIni: Yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .when('rangoFechas', {
            is: (value) => value === true,
            then: (schema) => schema.required('Fecha Inicial es requerida'),
            otherwise: (schema) => schema.nullable(),
        }),
    fechaFin: Yup.date()
        .transform((value, originalValue) => (originalValue === '' ? null : value))
        .when('rangoFechas', {
            is: (value) => value === true,
            then: (schema) => schema.required('Fecha Final es requerida')
                .min(Yup.ref('fechaIni'), 'Fecha Final debe ser mayor a Fecha Inicial'),
            otherwise: (schema) => schema.nullable(),
        }),
}).test(
    'at-least-one',
    '',
    function (values) {
        const { id, ruta, cliente, familia, oper, fecha, rangoFechas } = values;

        // Si rangoFechas es false, 'fecha' es considerado
        // Si rangoFechas es true, 'fecha' no se considera
        const fechaConsiderada = rangoFechas ? false : !!fecha;

        const isValid = !!id || !!ruta || !!cliente || !!familia || !!oper || fechaConsiderada;
        console.log('Validando at-least-one:', isValid, 'Valores:', values);

        if (!isValid) {
            return this.createError({
                path: 'general', // Asignar error al campo 'general'
                message: 'Al menos uno de los campos: Id, Ruta, Cliente, Familia, Operador o Fecha es requerido',
            });
        }

        return true;
    }
);

export default validationFormSchema