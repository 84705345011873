// InputText.jsx
import React from 'react';
import { useField, useFormikContext } from 'formik';

const InputTextForm = ({ label, onChangeAction = undefined, onBlurAction = undefined, onKeyDownAction = undefined, ...props }) => {
  const { values, initialValues } = useFormikContext();
  const [field, meta] = useField(props);
  const { name } = field;

  // Manejador de eventos de teclado para Enter
  const handleKeyDown = async (e) => {
    if (onKeyDownAction && e.key === 'Enter' && values[name] !== initialValues[name]) {
      e.preventDefault(); // Previene el comportamiento por defecto
      await onKeyDownAction();
    }
  };

  // Manejador de eventos de blur
  const handleBlur = async (e) => {
    field.onBlur(e);
    if (onBlurAction && values[name] !== initialValues[name]) {
      await onBlurAction();
    }
  };

  // Manejador de eventos de cambio
  const handleChange = async (e) => {
    field.onChange(e);
    if (onChangeAction) {
      // Usar setTimeout para llamar a onChangeAction después de que el estado se haya actualizado
      setTimeout(async () => {
        await onChangeAction(e);
      }, 0);
    }
  };

  return (
    <div className="w-full md:w-auto pt-2">
      <div className="relative h-12 w-full min-w-[100px]">
        <input
          {...field}
          {...props} // Propiedades de Formik
          onKeyDown={handleKeyDown} // Maneja el envío al presionar Enter
          onBlur={handleBlur} // Maneja el blur con lógica condicional
          onChange={handleChange} // Maneja el cambio con lógica condicional
          className={`peer h-full w-full rounded-[7px] 
            text-lg font-normal border border-suva-azul-50 border-t-transparent bg-white
            px-3 py-2.5 outline outline-0 transition-all 
            focus:border-2 focus:border-suva-azul-50 ${label ? 'focus:border-t-transparent' : ''} focus:outline-0 
            disabled:bg-suva-azul-10 disabled:text-gray-600`}
        />

        {label && (
          <label htmlFor={name} className="pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-base md:text-sm tracking-wide text-suva-gris-oscuro font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-sm peer-focus:leading-tight peer-focus:text-blue-600 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-suva-azul-50 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-suva-azul-50 peer-disabled:text-red peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            {label}
          </label>
        )}
      </div>
      {meta.touched && meta.error && (
        <div className="text-red-600 text-left">
          <span className='text-xs'>{meta.error}</span>
        </div>
      )}
    </div>
  );
};

export const CustomInputText = ({ label, ...props }) => {
  return (
    <div className="w-full md:w-auto pt-2">
      <div className="relative h-12 w-full min-w-[100px]">
        <input
          {...props}
          className={`peer h-full w-full rounded-[7px] 
            text-lg font-normal border border-suva-azul-50 border-t-transparent bg-white
            px-3 py-2.5 outline outline-0 transition-all 
            focus:border-2 focus:border-suva-azul-50 ${label ? 'focus:border-t-transparent' : ''} focus:outline-0 
            disabled:bg-suva-azul-10 disabled:text-gray-600`}
        />

        {label && (
          <label htmlFor={props.name} className="pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-base md:text-sm tracking-wide text-suva-gris-oscuro font-normal leading-tight transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-sm peer-focus:leading-tight peer-focus:text-blue-600 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-suva-azul-50 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-suva-azul-50 peer-disabled:text-red peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            {label}
          </label>
        )}
      </div>
    </div>
  );
};

export default InputTextForm;
