// ConsultaForm.js
import React from 'react';
import { Form, Formik, useFormikContext } from 'formik';
import { CheckBox, InputText, Select } from '../../../../../componentes/Formik';
import validationFormSchema from '../../../Schemas/SchemaManifiestosEmitidos';
import useFamilias from '../../../../../Hooks/UseFamilias';
import UseAgentesSelect from '../../../../../Hooks/UseAgentes';

// Componente para manejar los efectos de validación
const ValidationEffect = ({ onValidationError }) => {
    const { errors, submitCount } = useFormikContext();

    React.useEffect(() => {
        // if (submitCount > 0 && Object.keys(errors).length > 0) {
        if (submitCount > 0) {
            onValidationError(Object.keys(errors).length > 0);
        }
    }, [errors, submitCount, onValidationError]);

    return null;
};

const ManifiestosEmitidosForm = ({
    onSubmit,
    onValidationError,
    initialValues,
}) => {

    const { loadingData: getDataFam,
        agSelectOptFam
    } = useFamilias("idSelectFam")

    const { gettingData: getDataAgentes, agregarSelectAgDefault,
        idAgenteOper, operador } = UseAgentesSelect();

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationFormSchema}
            onSubmit={(values, { setFieldError }) => onSubmit(values, setFieldError)}
        >
            {(props) => {
                const { isSubmitting, values, errors, } = props;
                return (
                    <Form className="flex flex-col w-full p-2 gap-2">
                        {/* Componente para manejar los efectos de validación */}
                        <ValidationEffect onValidationError={onValidationError} />
                        {/* Campo oculto para errores generales */}
                        <input type="hidden" name="general" />
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <InputText
                                disabled={isSubmitting}
                                label="Id de manifiesto"
                                name="id"
                                type="number"
                                placeholder=""
                            />
                            <InputText
                                disabled={isSubmitting}
                                label="Ruta"
                                name="ruta"
                                type="number"
                                placeholder=""
                            />
                            <InputText
                                disabled={isSubmitting}
                                label="Clave del cliente"
                                type="text"
                                placeholder=""
                                name="cliente"
                            />
                            <InputText
                                disabled={isSubmitting || values.rangoFechas}
                                label="Fecha de Inicio"
                                type="date"
                                placeholder=""
                                name="fecha"
                            />
                            <Select
                                id="idSelectFam"
                                className="pt-[0.1235rem]"
                                data-te-select-init
                                label="Familia"
                                name="familia"
                                value={values.familia}
                                disabled={isSubmitting || getDataFam}
                            >
                                <option hidden defaultValue value=""></option>
                                {agSelectOptFam()}
                            </Select>
                            <Select
                                id={idAgenteOper}
                                disabled={isSubmitting || getDataAgentes}
                                className="pt-[0.1235rem]"
                                data-te-select-init
                                label={operador.label}
                                name={operador.name}
                            >
                                <option hidden defaultValue value=""></option>
                                {agregarSelectAgDefault('OPE', undefined)}
                            </Select>
                        </div>
                        <hr className="my-2 h-0.5 border-t-0 bg-neutral-200 dark:bg-white/10" />
                        <div className="flex justify-start w-full my-2">
                            <CheckBox name="rangoFechas">Por rango de fechas</CheckBox>
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                            <InputText
                                disabled={isSubmitting || !values.rangoFechas}
                                label="Fecha Inicial"
                                type="date"
                                placeholder=""
                                name="fechaIni"
                            />
                            <InputText
                                disabled={isSubmitting || !values.rangoFechas}
                                label="Fecha Final"
                                type="date"
                                placeholder=""
                                name="fechaFin"
                            />
                            <button
                                disabled={isSubmitting}
                                type="submit"
                                className="btn-secondary m-3 md:max-w-72"
                            >
                                Buscar Manifiestos emitidos
                            </button>
                        </div>
                        {/* Mostrar error global si existe */}
                        {errors.general && (
                            <div className="text-red-500 text-left text-sm mt-2">
                                {errors.general}
                            </div>
                        )}
                    </Form>
                )
            }}
        </Formik>
    );
}

export default ManifiestosEmitidosForm;
