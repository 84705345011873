import React, { useCallback, useMemo, useState } from 'react'
import Accordion from '../../../../componentes/Accordion/Accordion';
import { CustomGenericTable } from '../../../../componentes/Tables/CustomTable';
import CustomToastify from '../../../../componentes/Toasts/CustomToast';
import CircleSpiner from '../../../../componentes/Spinners/CircleSpiner';
import ManifiestosEmitidosForm from './components/ManifiestosEmitidos.Form';
import GenerarReporteButton from './components/GenerarReporte.Button';
import { columnSize } from '../../../../Constantes';

export default function ManifiestosEmitidos() {

    const [loadingData, setLoadingData] = useState(false);
    const [isValidForm, setIsValidForm] = useState(false)

    const [dataManifE, setDataManifE] = useState([])
    const initValues = {
        id: '',
        ruta: '',
        cliente: '',
        fecha: '',
        familia: '',
        oper: '',
        rangoFechas: false,
        fechaIni: "",
        fechaFin: ""
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "Id",
                enableColumnFilter: false
            },
            {
                accessorKey: "clave",
                header: "Clave",
                enableColumnFilter: true,
                filterFn: "includesString",
                size: 50,
                enableSorting: true
            },
            {
                accessorKey: "ciudad",
                header: "Ciudad",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "familia",
                header: "Familia",
                enableColumnFilter: false,
                enableSorting: false
            },
            {
                accessorKey: "nombre",
                header: "Nombre",
                enableColumnFilter: false,
                size: columnSize,
                enableSorting: false
            },
            {
                accessorKey: "ruta",
                header: "Ruta",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "orden",
                header: "Orden",
                enableColumnFilter: false,
                enableSorting: true,
                size: 100
            },
            {
                accessorKey: "servicio",
                header: "Servicio",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "dia",
                header: "Día",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "fvisita",
                header: "Fecha visita",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "salida",
                header: "Salida",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "manifiesto",
                header: "Manifiesto",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "planta",
                header: "Planta",
                enableColumnFilter: false,
                enableSorting: false,
                size: 100
            },
            {
                accessorKey: "rutaplanta",
                header: "Ruta Planta",
                enableColumnFilter: false,
                enableSorting: false,
                size: 250,
            }
        ],
        []
    );


    // Función para manejar la limpieza de la tabla al fallar la validación y activar bandera de error ene l formulario
    const handleValidationError = useCallback((flagError) => {
        setIsValidForm(!flagError)
        if (flagError)
            setDataManifE([]);
    }, []);

    async function obtenerManifiestos() {
        setLoadingData(true)
        // Suponiendo que fetchManifiestos es una función que llama a tu API
        // const fetchedData = await fetchManifiestos(clave, fechaIni, fechaFin);
        // setDataManifE(fetchedData);
        // Simulación de datos
        const fetchedData = [
            {
                id: 1,
                clave: '123',
                ciudad: 'Ciudad A',
                familia: 'Familia X',
                nombre: 'Nombre A',
                ruta: 'Ruta 1',
                orden: 1,
                servicio: 'Servicio 1',
                dia: 1,
                fvisita: '2024-01-01',
                salida: 'Salida 1',
                manifiesto: 'Manifiesto 1',
                planta: 'Planta 1',
                rutaplanta: 'Ruta Planta 1'
            },
            {
                id: 2,
                clave: '124',
                ciudad: 'Ciudad B',
                familia: 'Familia Y',
                nombre: 'Nombre B',
                ruta: 'Ruta 2',
                orden: 2,
                servicio: 'Servicio 2',
                dia: 2,
                fvisita: '2024-01-02',
                salida: 'Salida 2',
                manifiesto: 'Manifiesto 2',
                planta: 'Planta 2',
                rutaplanta: 'Ruta Planta 2'
            },
            // ... más datos
        ];
        setLoadingData(false)
        setDataManifE(fetchedData);
        return fetchedData;
    }
    const onSubmitObtenerManifestos = async (values) => {
        const { clave, fechaIni, fechaFin } = values;
        // Implementar la lógica para obtener los datos basados en 'clave' y fechas.
        // Aquí se simula una llamada a la API
        try {
            console.log('Obteniendo datos para la tabla con clave:', clave, 'y fechas:', fechaIni, fechaFin);
            await obtenerManifiestos();
        } catch (error) {
            console.error('Error al obtener los manifiestos:', error);
            CustomToastify({
                title: "Error",
                message: "Hubo un problema al obtener los manifiestos",
                type: 'error'
            });
        }
    };

    return (
        <>
            {(loadingData) && <CircleSpiner />}
            <div className="flex flex-col w-full p-2 gap-2">
                <Accordion
                    titulo="Datos de consulta"
                    idTarget="collapseDatosConsultaForm"
                    expanded="true"
                >

                    <ManifiestosEmitidosForm
                        initialValues={initValues}
                        onSubmit={onSubmitObtenerManifestos}
                        onValidationError={handleValidationError} // Pasar la función de limpieza

                    />

                </Accordion>
                <Accordion
                    titulo="Manifiestos emitidos"
                    idTarget="collapseManifiestosEmitidos"
                    expanded="true"
                >
                    <CustomGenericTable
                        columnsData={columns}
                        data={dataManifE}
                        optionsTable={{ ofText: "Manifiestos emitidos de" }}
                        setData={setDataManifE}
                        getData={async () => { if (isValidForm) await obtenerManifiestos() }}
                        isTableSelected={false}
                    />
                </Accordion>
            </div>
            <GenerarReporteButton isSubmitting={false} data={dataManifE} />
        </>
    )
}
