import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logoPlantilla from "../../imagenes/logo_plantilla.png";

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    headerContainer: {
        position: 'absolute',
        top: 20,
        left: 20,
        right: 20,
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 50,
        height: 50,
        marginRight: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        fontSize: 10,
        textAlign: 'center',
    },
    content: {
        marginTop: 55,
        marginBottom: 110,
        lineHeight: 1.5,
    },
    table: {
        display: 'table',
        width: '100%',
        marginVertical: 10,
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        borderBottomStyle: 'solid',
    },
    tableColFecha: {
        width: '20%', // Asignamos 20% para la columna Fecha
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
    tableColFalla: {
        width: '80%', // Asignamos 80% para la columna de Fallas
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
    tableCell: {
        margin: 4, // Reduce el margen interno
        padding: 4, // Reduce el relleno interno
        fontSize: 9, // Reduce el tamaño de la fuente
        lineHeight: 1, // Ajusta el interlineado
    },
});


const ReporteDiario = ({ data }) => { // Accede a data correctamente desde props
    console.log('Datos recibidos:', data); // Verifica que los datos lleguen correctamente

    const { ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo } = data || {};
    return (
        <Document>
            <Page size="Letter" orientation="portrait" style={styles.page}>
                <View style={styles.headerContainer} fixed>
                    <View style={styles.headerLeft}>
                        <Image style={styles.logo} src={logoPlantilla} />
                        <View>

                            <Text>Sistema de Administracion de SUVALSA</Text>
                            <Text>Reporte Diario de Fallas en la Unidad</Text>
                            <Text>
                                Ruta:{ruta} - {nombre} del {fecha} al {fInicio}
                            </Text>
                            <Text>Vehiculo:{vehiculo}  Marca: Placas: </Text>
                        </View>
                    </View>
                </View>



                <View style={styles.content}>
                    <View style={styles.table}>
                        {/* Encabezado de la tabla */}
                        <View style={styles.tableRow}>
                            <View style={styles.tableColFecha}>
                                <Text style={styles.tableCell}>Fecha</Text>
                            </View>
                            <View style={styles.tableColFalla}>
                                <Text style={styles.tableCell}>Indicar la Falla Especifica Detectada</Text>
                            </View>
                        </View>
                        {/* Genera 30 filas de ejemplo */}
                        {[...Array(30)].map((_, index) => (
                            <View key={index} style={styles.tableRow}>
                                <View style={styles.tableColFecha}>
                                    <Text style={styles.tableCell}></Text>
                                </View>
                                <View style={styles.tableColFalla}>
                                    <Text style={styles.tableCell}></Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>

                <View style={styles.footer} fixed>
                    <View style={styles.line} />
                    <View style={styles.line} />

                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Text style={{ fontSize: 8 }}>Operador Responsable:_____________________</Text>
                            <Text style={{ fontSize: 8 }}>Operador Auxiliar y/o Personal de Apoyo:_______________</Text>
                        </View>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Text style={{ fontSize: 8 }}>Firma:__________________________________</Text>
                            <Text style={{ fontSize: 8 }}>Firma:__________________________________</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 8 }}>Fecha:__________________________________</Text>
                            <Text style={{ fontSize: 8 }}>Fecha:__________________________________</Text>
                        </View>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Text style={{ fontSize: 8 }}>Recibe:___________________________</Text>
                            <Text style={{ fontSize: 8 }}>Orden de servicio No.:__________</Text>
                        </View>
                        <View style={{ flex: 1, marginRight: 5 }}>
                            <Text style={{ fontSize: 8 }}>Firma:_________________________</Text>
                            <Text style={{ fontSize: 8 }}>Localidad:_______________</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 8 }}>Fecha:_______________________________</Text>
                            <Text style={{ fontSize: 8 }}>Taller:_________________________</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontSize: 8 }}></Text>
                            <Text style={{ fontSize: 8 }}>Telefono:________________</Text>
                        </View>
                    </View>
                </View>

            </Page>
        </Document>
    );
};

export default ReporteDiario;