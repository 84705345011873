// CellInputValid.jsx
import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { AiOutlineWarning } from 'react-icons/ai'; // Icono de advertencia
import './cellInputValid.css'; // Importa los estilos específicos
import { MemoInput } from '../CellInput';

const CellInputValid = ({ columnId, row, type, value, onChange = () => { }, min, className = "", onKeyDown, readOnly = false }) => {
    // Acceder al objeto de errores por campo
    const fieldError = row.original.errors?.[columnId]?.hasError;
    const fieldErrorMessage = row.original.errors?.[columnId]?.message;

    //const { errorColumnId, error, errorMessage } = row.original;
    const [showTooltip, setShowTooltip] = useState(false);
    const [clicked, setClicked] = useState(false);
    const tooltipRef = useRef();
    const iconWrapperRef = useRef();
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });

    const handleClickOutside = (event) => {
        if (
            tooltipRef.current &&
            !tooltipRef.current.contains(event.target) &&
            iconWrapperRef.current &&
            !iconWrapperRef.current.contains(event.target)
        ) {
            setShowTooltip(false);
            setClicked(false)
        }
    };

    useEffect(() => {
        if (showTooltip) {
            document.addEventListener('mousedown', handleClickOutside);
            // Calcular la posición del tooltip
            const inputRect = iconWrapperRef.current.getBoundingClientRect();
            const tooltipWidth = 200; // Ancho fijo del tooltip
            const tooltipHeight = 60; // Altura aproximada del tooltip (ajusta según sea necesario)
            let top = inputRect.top + window.scrollY - tooltipHeight; // 10px arriba del input
            let left = inputRect.left + (inputRect.width / 2) - (tooltipWidth / 2) + window.scrollX; // Centrado horizontalmente

            // Verificar si el tooltip se sale de la ventana a la izquierda
            if (left < 10 + window.scrollX) {
                left = 10 + window.scrollX; // 10px desde la izquierda
            }

            // Verificar si el tooltip se sale de la ventana a la derecha
            if (left + tooltipWidth > window.innerWidth - 10 + window.scrollX) {
                left = window.innerWidth - tooltipWidth - 10 + window.scrollX; // 10px desde la derecha
            }

            // Verificar si el tooltip se sale de la ventana hacia arriba
            if (top < 10 + window.scrollY) {
                top = inputRect.bottom + 10 + window.scrollY; // Posicionar debajo del input si no hay espacio arriba
            }

            setTooltipPosition({ top, left });
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);

    return (
        <div className="cell-valid-container">
            {fieldError && (
                <div className="error-icon-wrapper" ref={iconWrapperRef}>
                    <AiOutlineWarning
                        className="error-icon text-red-500"
                        onClick={() => setShowTooltip(!showTooltip)}
                        // onMouseEnter={() => setShowTooltip(true)}
                        // onMouseLeave={() => setShowTooltip(false)}
                        aria-label="Error de validación"
                        role="button"
                        tabIndex={0}
                    /*onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            setShowTooltip(!showTooltip);
                        }
                    }}*/
                    />
                    {showTooltip && createPortal(
                        <div className="error-tooltip" ref={tooltipRef} style={{ top: tooltipPosition.top, left: tooltipPosition.left }}>
                            {fieldErrorMessage}
                        </div>,
                        document.body
                    )}
                </div>
            )}
            <MemoInput
                type={type}
                readOnly={readOnly}
                value={value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                // onClick={() => {
                //     if (error && errorColumnId === columnId) {
                //         setShowTooltip(!showTooltip)
                //         setClicked(!clicked)
                //     }
                // }}
                onMouseEnter={() => { if (fieldError && !clicked) setShowTooltip(true) }}
                onMouseLeave={() => { if (fieldError && !clicked) setShowTooltip(false) }}
                min={min}
                className={`${fieldError ? 'error-input cell-valid-inputText' : 'cell-valid-inputText'} ${className}`}
            />

        </div>
    );
};

export default CellInputValid;
