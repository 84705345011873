// BitacoraDocument.jsx
import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from '@react-pdf/renderer';

// -------------------------------------------------------------------
// 1. Datos de ejemplo: Filas que se muestran en tu captura.
//    Ajusta o amplía según la información real de tu documento.
// -------------------------------------------------------------------
const itemsTabla = [
    { id: 1, texto: 'Indicador Presión de Aceite' },
    { id: 2, texto: 'Indicador Presión Aire/Vacio' },
    { id: 3, texto: 'Dispositivo advertencia poco aire/vacío' },
    { id: 4, texto: 'TABLERO DE INSTRUMENTOS' },
    { id: 5, texto: 'Velocímetro' },
    { id: 6, texto: 'Calentador / Desempañador' },
    { id: 7, texto: 'Batería' },
    { id: 8, texto: 'Motor' },
    { id: 9, texto: 'LUCES TABLERO' },
    { id: 10, texto: 'Direccionales' },
    { id: 11, texto: 'Estacionamiento' },
    { id: 12, texto: 'Galibo (Altura)' },
    { id: 13, texto: 'Interior' },
    { id: 14, texto: 'Advertencia' },
    { id: 15, texto: 'Demarcadores' },
    { id: 16, texto: 'ACCESORIOS' },
    { id: 17, texto: 'Claxon / Corneta' },
    { id: 18, texto: 'Cinturón de Seguridad [mínimo operador]' },
    { id: 19, texto: 'Parabrisas sin fisuras' },
    { id: 20, texto: 'Limpiadores' },
    { id: 21, texto: 'Espejo Retrovisor [2]' },
    { id: 22, texto: 'Volante [Sin juego excesivo]' },
    { id: 23, texto: 'Freno de Pie' },
    { id: 24, texto: 'Frenos de Emergencia' },
    { id: 25, texto: 'FRENTE [EXTERIOR]' },
    { id: 26, texto: 'Defensas [sujeción]' },
    { id: 27, texto: 'Faros principales [color/funcionamiento]' },
    { id: 28, texto: 'Llantas [no renovadas eje delantero]' },
    { id: 29, texto: 'Rines sin fisuras' },
    { id: 30, texto: 'Biros completos' },
    { id: 31, texto: 'Guardafangos [Loderas]' },
    { id: 32, texto: 'LUCES EXTERIORES' },
    { id: 33, texto: 'Demarcadoras' },
    { id: 34, texto: 'Direccionales' },
    { id: 35, texto: 'Galibo' },
    { id: 36, texto: 'Advertencia' },
    { id: 37, texto: 'LADO IZQUIERDO' },
    { id: 38, texto: 'Tanque de combustible sin fugas' },
    // ... Continúa si tu documento lo requiere
];

// -------------------------------------------------------------------
// 2. Definimos las columnas de evaluación que se repiten (B, M, NA...)
//    En tu documento se ve la repetición de B, M, NA muchas veces.
//    Ajusta la cantidad según necesites que se repita horizontalmente.
// -------------------------------------------------------------------
const numRepeticiones = 10;  // EJEMPLO: 10 grupos de [B, M, NA]
const columnasEvaluacion = Array.from({ length: numRepeticiones }, (_, i) => ({
    id: i + 1,
    labels: ['B', 'M', 'NA'],
}));

// -------------------------------------------------------------------
// 3. Estilos
// -------------------------------------------------------------------
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        padding: 10,
    },
    // Usamos A4 en orientación Landscape para un PDF más ancho
    // y poder “replicar” la tabla en una sola hoja lo mejor posible.
    // (Si aún así no cabe todo, podrías usar un tamaño custom.)
    logo: {
        width: 100,
        height: 60,
        marginRight: 10,
    },
    headerRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
        alignItems: 'center',
    },
    titleContainer: {
        textAlign: 'center',
        flex: 1,
    },
    title: {
        fontSize: 11,
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: 9,
    },
    tableHeaderRow: {
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1 solid black',
        marginBottom: 2,
    },
    tableHeaderText: {
        fontSize: 8,
        fontWeight: 'bold',
        textAlign: 'center',
        borderRight: '1 solid black',
        padding: 2,
    },
    // Columna izquierda (SISTEMA A EVALUAR / Ítems)
    colItem: {
        width: '30%',
        borderRight: '1 solid black',
        padding: 2,
    },
    colItemText: {
        fontSize: 7,
    },
    // Contenedor de las celdas B, M, NA repetidas
    colChecks: {
        flexDirection: 'row',
        width: '70%', // la parte derecha de la tabla
    },
    // Para cada "grupo" B, M, NA
    groupContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        borderRight: '1 solid black',
    },
    checkBox: {
        width: 15,
        height: 15,
        margin: 1,
        border: '1 solid black',
        alignItems: 'center',
        justifyContent: 'center',
    },
    checkBoxLabel: {
        fontSize: 6,
    },
    // Para cada fila
    tableRow: {
        flexDirection: 'row',
        borderBottom: '1 solid #ccc',
    },
    // Info superior (fecha, firma, día, etc.)
    infoTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 8,
        marginBottom: 3,
    },
    infoTopBlock: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    label: {
        fontWeight: 'bold',
    },
    // Pie de página / firma
    footerSignature: {
        marginTop: 5,
        fontSize: 8,
    },
});

// -------------------------------------------------------------------
// 4. Componente principal
// -------------------------------------------------------------------
const ReporteDiarioFallas = () => {
    return (
        <Document>
            {/* Definimos que sea A4 en paisaje */}
            <Page size="A4" orientation="landscape" style={styles.page}>

                {/* ----------------------------------------- */}
                {/* ENCABEZADO CON LOGO Y TITULOS             */}
                {/* ----------------------------------------- */}
                <View style={styles.headerRow}>
                    {/* Logo (ajusta la ruta a tu imagen real) */}
                    <Image
                        src="https://via.placeholder.com/150?text=Logo+Suvalsa"
                        style={styles.logo}
                    />

                    <View style={styles.titleContainer}>
                        <Text style={styles.title}>
                            NOM-006-SCT2-2011 - Sistema de Administración de Suvalsa
                        </Text>
                        <Text style={styles.subtitle}>
                            Bitácora Diaria de Condiciones de Operación. Ruta 15 - FB CHIAPAS del 01/12/2024
                        </Text>
                    </View>
                </View>

                {/* Info de la empresa y del formulario */}
                <Text style={{ fontSize: 8, marginBottom: 2 }}>
                    <Text style={{ fontWeight: 'bold' }}>Nombre Transportista:</Text> SUVALSA SA DE CV
                    | José Vasconcelos 39 Edif 1 Depto 004 PB, Sta Mª Trinidad Tlalpan, México, DF
                    | Tel: (55) 54852800 [744] 4508813
                </Text>

                {/* Info adicional: origen, destino, día, fecha, etc. */}
                <View style={styles.infoTop}>
                    <Text><Text style={styles.label}>Origen:</Text> ________</Text>
                    <Text><Text style={styles.label}>Destino:</Text> ________</Text>
                    <Text><Text style={styles.label}>Día:</Text> ___</Text>
                    <Text><Text style={styles.label}>Fecha:</Text> ___/___/___</Text>
                    <Text><Text style={styles.label}>km por Día:</Text> _____</Text>
                </View>
                <View style={styles.infoTop}>
                    <Text><Text style={styles.label}>Firma del Operador:</Text> _________________</Text>
                    <Text><Text style={styles.label}>Tipo de Vehículo:</Text> CAMIÓN C-2 UNITARIO</Text>
                    <Text><Text style={styles.label}>Tipo Licencia:</Text> E</Text>
                    <Text><Text style={styles.label}>Vigencia Licencia:</Text> _________</Text>
                </View>

                {/* Simbología y Abreviaturas */}
                <Text style={{ fontSize: 8, marginBottom: 3 }}>
                    <Text style={styles.label}>Simbología Evaluación:</Text> B = Bien | M = Mal | NA = No Aplica | X = No Aceptable
                    &nbsp;|&nbsp;
                    <Text style={styles.label}>Abreviaturas:</Text> I (Izquierda), D (Derecha)
                </Text>

                {/* ----------------------------------------- */}
                {/* TABLA PRINCIPAL                          */}
                {/* ----------------------------------------- */}

                {/* Cabecera de la tabla (SISTEMA A EVALUAR, luego repite B, M, NA...) */}
                <View style={styles.tableHeaderRow}>
                    <View style={styles.colItem}>
                        <Text style={styles.tableHeaderText}>SISTEMA A EVALUAR</Text>
                    </View>
                    {/* Repetimos tantas secciones de B, M, NA como definamos en columnasEvaluacion */}
                    <View style={styles.colChecks}>
                        {columnasEvaluacion.map((col, idx) => (
                            <View key={col.id} style={[styles.groupContainer, idx === columnasEvaluacion.length - 1 ? { borderRight: 'none' } : {}]}>
                                {col.labels.map((label) => (
                                    <View key={label} style={styles.checkBox}>
                                        <Text style={{ fontSize: 6, fontWeight: 'bold' }}>{label}</Text>
                                    </View>
                                ))}
                            </View>
                        ))}
                    </View>
                </View>

                {/* Filas de la tabla (1,2,3... 38) con sus casillas B, M, NA */}
                {itemsTabla.map((item, rowIndex) => (
                    <View key={item.id} style={styles.tableRow}>
                        {/* Columna con el texto del ítem */}
                        <View style={styles.colItem}>
                            <Text style={styles.colItemText}>
                                {item.id}. {item.texto}
                            </Text>
                        </View>
                        {/* Repetimos el bloque de B, M, NA (o lo que hayas definido) */}
                        <View style={styles.colChecks}>
                            {columnasEvaluacion.map((col, idx) => (
                                <View key={col.id} style={[styles.groupContainer, idx === columnasEvaluacion.length - 1 ? { borderRight: 'none' } : {}]}>
                                    {col.labels.map((label) => (
                                        <View key={label} style={styles.checkBox}>
                                            <Text style={styles.checkBoxLabel}>{label}</Text>
                                        </View>
                                    ))}
                                </View>
                            ))}
                        </View>
                    </View>
                ))}

                {/* Podrías agregar más secciones, más filas o un pie de tabla según requieras */}

            </Page>
        </Document>
    );
};

export default ReporteDiarioFallas;