import FetchApi, { FetchApiPatch, FetchApiPost, FetchApiPut } from "./ConfigFetch";

const URL_MANIFIESTOS = 'https://suvalsa-home.ddns.net:8093/queryMani/manifquery/'
const URL_MANIFIESTOS_API = 'https://suvalsa-home.ddns.net:8070/api/manif/'
const URL_MANIF_REC_API = 'https://suvalsa-home.ddns.net:8070/api/recolecta/'
const METHOD_BUSCAR = "with-clients";
const METHOD_BUSCAR_NATIVE = "with-clients-native";
const METHOD_SEARCH = "search"


export async function ObtenerClientesManifiestos(clave) {
  let dataClientes = []

  dataClientes = await FetchApi(URL_MANIFIESTOS.concat(clave))

  return dataClientes.data;
}

//get
export async function ObtenerMaxManifiesto() {
  let maxManif = ''

  maxManif = await FetchApi(URL_MANIFIESTOS_API.concat("max-clave"))

  return maxManif.data;
}

export async function ObtenerClientesManifiestosPorFecha(ruta, fecha) {
  let dataClientes = []

  dataClientes = await FetchApi(URL_MANIFIESTOS_API.concat(METHOD_BUSCAR).concat("?ruta=" + ruta + "&fruta=" + fecha))

  return dataClientes.data;
}

//ObtenerClientesManifiestosPorFechaNative - esto retornara los manifiestos para generar el manifiesto:
export async function ObtenerClientesManifiestosPorFechaNative(ruta, fecha) {
  let dataClientes = []

  dataClientes = await FetchApi(URL_MANIFIESTOS_API.concat(METHOD_BUSCAR_NATIVE).concat("?ruta=" + ruta + "&fruta=" + fecha))

  return dataClientes.data;
}

//https://suvalsa-home.ddns.net:8070/api/manif/search?ruta=6&fruta=2026-01-20
export async function ObtenerManifiestosPorRutaFecha(ruta, fecha) {
  let dataRuta = []

  dataRuta = await FetchApi(URL_MANIFIESTOS_API.concat(METHOD_SEARCH).concat("?ruta=" + ruta + "&fruta=" + fecha))

  return dataRuta.data;
}

//https//suvalsa-home.ddns.net:8070/api/recolecta/filter?ruta=15&fruta=2025-01-01
export async function ObtenerManifiestosRecolectaPorRutaFecha(ruta, fecha) {
  let dataRec = []

  dataRec = await FetchApi(URL_MANIF_REC_API.concat("filter").concat("?ruta=" + ruta + "&fruta=" + fecha))
  return dataRec.data;
}

export async function Generarmanifiesto(data) {
  let responseGenMan = null;
  responseGenMan = await FetchApiPost(
    URL_MANIFIESTOS_API.concat("generarmanifiesto"),
    JSON.stringify(data)
  );

  if (!responseGenMan.headerResponse.statusOk) {
    throw new Error(responseGenMan.bodyResponse.error)
  }

  return responseGenMan;
}

export async function ActualizarManifiesto(numero, manifiesto, data) {

  let responseUpdateMan = null;
  responseUpdateMan = await FetchApiPut(URL_MANIFIESTOS_API.concat("actualizarmanifiesto/").concat(numero).concat('/', manifiesto), JSON.stringify(data))

  if (!responseUpdateMan.headerResponse.statusOk) {
    throw new Error(responseUpdateMan.bodyResponse.error)
  }

  return responseUpdateMan;
}
//https://suvalsa-home.ddns.net:8070/api/manif//{id}/salida
// {
//   "salida": ""
// }
//Patch
export async function ActualizarSalidaById(id, data) {
  const URL_PATCH = URL_MANIFIESTOS_API.concat(id+"/salida");
  let responseModificar = null;
  responseModificar = await FetchApiPatch(URL_PATCH, JSON.stringify(data));
  return { responseModificar };
}
