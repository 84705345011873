import React, { memo } from 'react';

// Componente Input reutilizable
export const MemoInput = memo(function InputComponent(
  { 
    ref, type, value, onChange, min, className = "", onKeyDown, readOnly = false,
    onClick, onMouseEnter, onMouseLeave
  }) {
  return (
    <input ref={ref}
      type={type}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      min={min}
      className={`border rounded p-1 ${className}`}
      readOnly={readOnly}
    />
  );
});
MemoInput.displayName = 'MemoInput';