import React from 'react';
import { Document, Page, Text, StyleSheet } from '@react-pdf/renderer';

// Definir los estilos del PDF
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
    },
    text: {
        position: 'absolute',
        fontSize: 10,
    },
    text2: {
        position: 'absolute',
        fontSize: 8,
    },
});

const AltaManifiestosPdf = ({ datos }) => (
    <Document>
        {datos.map((registro, index) => {
            const fechaVisita = new Date(registro.manifFruta);
            console.log('Fecha visita:', fechaVisita);
            const añoVisita = isNaN(fechaVisita) ? 'Fecha inválida' : fechaVisita.getFullYear();
            console.log('Fecha año:', añoVisita);
            const RutaAsignada = parseInt(registro.manifRuta, 10);
            console.log('Ruta asignada (parseada):', RutaAsignada);
            return (
                <Page key={index} size="A4" style={styles.page}>
                    <Text style={{ ...styles.text, top: 134.202, left: 137 }}>{registro.manifCliente}</Text>
                    <Text style={{ ...styles.text, top: 134.202, left: 266 }}>
                        {registro.clienteRegistro === "" ? "TRÁMITE" : registro.clienteRegistro}
                    </Text>
                    <Text style={{ ...styles.text, top: 104.202, left: 503.64 }}>{registro.manifClave}</Text>
                    <Text style={{ ...styles.text, top: 134.202, left: 503.64 }}>{registro.manifClave}</Text>
                    <Text style={{ ...styles.text, top: 154.199, left: 225 }}>{registro.clienteNombre}</Text>
                    <Text style={{ ...styles.text, top: 174.199, left: 122 }}>{registro.clienteCalle} {registro.clienteColonia} </Text>
                    <Text style={{ ...styles.text, top: 191.199, left: 189.72 }}>{registro.clienteCiudad}</Text>
                    <Text style={{ ...styles.text, top: 191.199, left: 499.16 }}> {registro.clienteEstado}</Text>
                    <Text style={{ ...styles.text, top: 174.199, left: 503.16 }}>{registro.clienteCp}</Text>
                    <Text style={{ ...styles.text, top: 211.199, left: 137 }}>{registro.clienteTelefono}</Text>

                    {RutaAsignada === 46 ? (
                        <>
                            <Text style={{ ...styles.text2, top: 252.199, left: 70 }}>Liquido Fijador o Revelador Gastado RX RP 7/57</Text>
                            <Text style={{ ...styles.text, top: 252.199, left: 489 }}>Kg</Text>

                            <Text style={{ ...styles.text, top: 460.199, left: 250.72 }}>Suvalsa SA de CV </Text>
                            <Text style={{ ...styles.text, top: 480.199, left: 126.72 }}>José  Vasconcelos 39 Edif 1 Depto 004 Santísima Trinidad Tlalpan, México DF CP14429 (55) 54852800</Text>
                            <Text style={{ ...styles.text, top: 500.199, left: 373.44 }}>09-I-18-18</Text>

                            <Text style={{ ...styles.text, top: 557.112, left: 266 }}>{añoVisita}</Text>
                            <Text style={{ ...styles.text, top: 591.202, left: 89.16 }}>México, Ciudad de México - Acapulco,Guerrero</Text>

                            <Text style={{ ...styles.text, top: 642.202, left: 225 }}>SI EQUIPO Y SERVICIOS, S.A. DE C.V.</Text>
                            <Text style={{ ...styles.text, top: 661.202, left: 225 }}>15-II-02-2024</Text>
                            <Text style={{ ...styles.text, top: 661.202, left: 350.44 }}>55 53840088</Text>
                            <Text style={{ ...styles.text, top: 675.202, left: 126.72 }}>Rio Lerma #22, Fracc. San Nicolas Tlaxcolpan, Tlalnepantla de baz,Edo. De Mexico C.P.54073</Text>

                            <Text style={{ ...styles.text, top: 740, left: 137 }}></Text>
                            <Text style={{ ...styles.text, top: 740, left: 350.44 }}>Gerente En Planta</Text>
                        </>
                    ) : (
                        <>
                            <Text style={{ ...styles.text, top: 252.199, left: 70 }}>SANGRE OTRO TIPO</Text>
                            <Text style={{ ...styles.text, top: 252.199, left: 489 }}>Litros</Text>

                            <Text style={{ ...styles.text, top: 272.199, left: 70 }}>PATOLÓGICO OTRO TIPO</Text>
                            <Text style={{ ...styles.text, top: 272.199, left: 489 }}>Litros</Text>

                            <Text style={{ ...styles.text, top: 292.199, left: 70 }}>NO ANÁTOMICO OTRO TIPO</Text>
                            <Text style={{ ...styles.text, top: 292.199, left: 489 }}>Litros</Text>

                            <Text style={{ ...styles.text, top: 312.199, left: 70 }}>PUNZOCORTANTE OTRO TIPO</Text>
                            <Text style={{ ...styles.text, top: 312.199, left: 489 }}>Litros</Text>

                            <Text style={{ ...styles.text, top: 332.199, left: 70 }}>CULTIVOS Y CEPAS OTRO TIPO</Text>
                            <Text style={{ ...styles.text, top: 332.199, left: 489 }}>Litros</Text>


                            <Text style={{ ...styles.text, top: 460.199, left: 250.72 }}>Suvalsa SA de CV </Text>
                            <Text style={{ ...styles.text, top: 480.199, left: 126.72 }}>José  Vasconcelos 39 Edif 1 Depto 004 Santísima Trinidad Tlalpan, México DF CP14429 (55) 54852800</Text>
                            <Text style={{ ...styles.text, top: 500.199, left: 373.44 }}>09-I-18-18</Text>

                            <Text style={{ ...styles.text, top: 557.112, left: 266 }}>{añoVisita}</Text>
                            <Text style={{ ...styles.text, top: 591.202, left: 89.16 }}>México, Ciudad de México - Acapulco,Guerrero</Text>

                            <Text style={{ ...styles.text, top: 642.202, left: 225 }}>SUVALSA, S.A. DE C.V.</Text>
                            <Text style={{ ...styles.text, top: 661.202, left: 225 }}>12-II-01-2024</Text>
                            <Text style={{ ...styles.text, top: 661.202, left: 350.44 }}>744 1267034</Text>
                            <Text style={{ ...styles.text, top: 675.202, left: 126.72 }}>Carretera al Arenal km 1+500 Localidad El Bejuco, Acapulco de Juárez, Gro. CP 39931</Text>

                            <Text style={{ ...styles.text, top: 740, left: 137 }}>Tomás Paz Valderrama</Text>
                            <Text style={{ ...styles.text, top: 740, left: 350.44 }}>Gerente</Text>
                        </>
                    )}

                </Page>
            );
        })}
    </Document>
);

export default AltaManifiestosPdf;