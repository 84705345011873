import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useTable } from "../../../componentes/Tables/VirtualTable/useTable";
import { useRef } from "react";
import useRutaPlanta from "../../../Hooks/UseRutaPlanta";
import { MemoInput } from "../../../componentes/Tables/components/Cell/CellInput";
import dayjs from "dayjs";
import CellInputValid from "../../../componentes/Tables/components/Cell/InputValid/CellInputValid";
import VirtualTable from "../../../componentes/Tables/VirtualTable/VirtualTable.table";

const AltaManifiestosTable = ({
    clave,
    fInicio,
    columnCustomFilters,
    setCustomColumnFilters,
    rutaData,
    data,
    rowSelection,
    setRowSelection,
    setData,
    getData,
}) => {
    const { plantasCat } = useRutaPlanta({ category: "PLTA" })

    //   const msjErrorQuantity = 'El día debe ser mayor a 0';
    //   const msjErrorDay = 'El día debe ser menor a la duración del viaje';

    const isTableSelected = true;

    const [columnFilters, setColumnFilters] = useState([]);
    // {
    //   id: 'clave',
    //   asc: true, // sort by clave in asc order by default
    // },

    const [sorting, setSorting] = React.useState([
        {
            id: "clave",
            asc: "true"
        }
    ]);

    // Definir las columnas con celdas editables y filtros
    const columns = useMemo(() => [
        {
            accessorKey: "id",
            header: "Id",
            enableColumnFilter: false,
            size: 50,
        },
        {
            accessorKey: "clave",
            header: "Clave",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            isFrozen: true, // Columna fija
            columnType: 'number',
        },
        {
            accessorKey: "ciudad",
            header: "Ciudad",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 400,
            columnType: "text",
        },
        {
            accessorKey: "familia",
            header: "Familia",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 150,
            columnType: "text",
        },
        {
            accessorKey: "nombre",
            header: "Nombre",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 450,
            columnType: "text",
        },
        {
            accessorKey: "ruta",
            header: "Ruta",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "text",
        },
        {
            accessorKey: "orden",
            header: "Orden",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "text",
        },
        {
            accessorKey: "servicio",
            header: "Servicio",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "text",
        },
        {
            accessorKey: "dia",
            header: "Día",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 100,
            columnType: "number",
            cell: ({ column: { id: columnId }, row, getValue }) => {
                const servicio = row.original.servicio;
                const isManifNotEditable = (
                    servicio === "CB" ||
                    servicio === "CI" ||
                    servicio === "TI")

                const isNotEditable = isManifNotEditable && (row.original.salida || (row.original.manifiesto)) && fInicio?.current
                //row, type, value, min, className = "", onKeyDown, onChange, readOnly
                //(!row.original.salida || (!row.original.manifiesto)) && fInicio)
                return (
                    <CellInputValid
                        readOnly={isNotEditable}
                        columnId={columnId}
                        row={row}
                        type={"number"}
                        min={"0"}
                        value={getValue() || ''}
                        onKeyDown={(e) => handleKeyDown(e)}
                        onChange={isNotEditable ? undefined : (e) => handleCellEdit(row.original.id, columnId, e.target.value)}
                    />
                )
            }
        },
        {
            accessorKey: "fvisita",
            header: "Fecha visita",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 160,
            columnType: 'date',
        },
        {
            accessorKey: "salida",
            header: "Salida",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 120,
            columnType: "text",
        },
        {
            accessorKey: "manifiesto",
            header: "No. Manifiesto",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 120,
            cell: ({ column: { id: columnId }, row, getValue }) => {
                const servicio = row.original.servicio;
                const isNotEditable = (
                    servicio === "CB" ||
                    servicio === "CI" ||
                    servicio === "TI")

                if (isNotEditable)
                    return (
                        <MemoInput
                            readOnly={isNotEditable}
                            type="text"
                            value={getValue() || ''}
                            onKeyDown={(e) => handleKeyDown(e)}
                        />
                    )
                else
                    return (
                        <CellInputValid
                            readOnly={isNotEditable}
                            row={row}
                            columnId={columnId}
                            type={"text"}
                            value={getValue() || ''}
                            onKeyDown={(e) => handleKeyDown(e)}
                            onChange={(e) => handleCellEdit(row.original.id, columnId, e.target.value)}
                        />
                    )
            }
        },
        {
            accessorKey: "planta",
            header: "Planta",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 275,
            columnType: 'select',
            selectOptions: plantasCat,
        },
        {
            accessorKey: "rutaplanta",
            header: "Ruta Planta",
            enableColumnFilter: true,
            filterFn: "includesString",
            enableSorting: true,
            size: 400,
            columnType: "text",
        },

    ], [plantasCat]);

    // Inicializar la tabla usando useTable
    const { table } = useTable({
        isSelected: isTableSelected,
        setData,
        data,
        rowSelection,
        setRowSelection,
        columns,
        defaultColumn: {},
        columnFilters: columnCustomFilters ?? columnFilters,
        setColumnFilters: setCustomColumnFilters ?? setColumnFilters, // Pasar esta función
        sorting, // Pasar el estado de sorting
        setSorting, // Pasar el actualizador de sorting
    });

    // Crear un ref para 'data' y actualizarlo cuando 'data' cambie
    const dataRef = useRef(data);
    // Función para manejar eventos de teclado con acceso al data actualizado
    const handleKeyDown = useCallback((e) => {
        const key = e.key;

        if (!["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].includes(key)) {
            return;
        }

        e.preventDefault();

        // Encontrar la celda actual
        const currentElement = e.target;
        const parentCell = currentElement.closest('div[data-row][data-col]');
        if (!parentCell) return;

        const rowIndex = parseInt(parentCell.getAttribute('data-row'), 10);
        const colIndex = parseInt(parentCell.getAttribute('data-col'), 10);

        // Verificar que rowIndex y colIndex son números válidos
        if (isNaN(rowIndex) || isNaN(colIndex)) {
            console.warn("rowIndex o colIndex no son números válidos:", rowIndex, colIndex);
            return;
        }

        let newRow = rowIndex;
        let newCol = colIndex;

        const totalRows = dataRef.current.length;
        const allColumns = table.getVisibleFlatColumns(); // Asegúrate de usar solo columnas visibles
        const totalCols = allColumns.length;

        switch (key) {
            case "ArrowUp":
                newRow = (rowIndex - 1 + totalRows) % totalRows;
                break;
            case "ArrowDown":
                newRow = (rowIndex + 1) % totalRows;
                break;
            case "ArrowLeft":
                newCol = (colIndex - 1 + totalCols) % totalCols;
                break;
            case "ArrowRight":
                // Implementar wrap-around horizontal
                if (colIndex === totalCols - 1) {
                    newCol = 0; // Mover al inicio de la misma fila
                } else {
                    newCol = colIndex + 1;
                }
                break;
            default:
                break;
        }

        // Implementar el wrap-around en columnas izquierda
        if (key === "ArrowRight" && colIndex === totalCols - 1) {
            newCol = 0; // Mover al inicio de la misma fila
        }
        if (key === "ArrowLeft" && colIndex === 0) {
            newCol = totalCols - 1; // Mover al final de la misma fila
        }

        // Encontrar la celda objetivo
        const targetCell = document.querySelector(
            `[data-row="${newRow}"][data-col="${newCol}"] input, [data-row="${newRow}"][data-col="${newCol}"] select`
        );

        if (targetCell) {
            targetCell.focus();
        } else {
            console.warn(`No se encontró el elemento en data-row="${newRow}" data-col="${newCol}"`);
        }
    }, [table]);

    // Función optimizada para manejar la edición de celdas
    const handleCellEdit = useCallback((id, field, value) => {
        setData(prevData => {
            const rowIndex = prevData.findIndex(item => item.id === id);
            if (rowIndex === -1) return prevData; // Fila no encontrada
            // Copiar la fila actual
            let updatedRow = { ...prevData[rowIndex], [field]: value };
            // Inicializar o copiar el objeto de errores correctamente
            const updatedErrors = { ...(updatedRow.errors || {}) };
            //Validaciones
            if (field === 'dia') {
                const days = Number(value);
                if (isNaN(days) || days <= 0) {
                    updatedErrors.dia = {
                        hasError: true,
                        message: 'El día debe ser mayor a 0',
                    };
                    updatedRow.fvisita = ""; // Limpiar fvisita
                } else if (days > rutaData.duracion.current) {
                    updatedErrors.dia = {
                        hasError: true,
                        message: 'El día debe ser menor a la duración del viaje',
                    };
                    updatedRow.fvisita = ""; // Limpiar fvisita
                } else {
                    const date = dayjs(fInicio.current);
                    const newFvisita = date.add(days - 1, 'days').format('YYYY-MM-DD');
                    updatedRow.fvisita = newFvisita;

                    updatedErrors.dia = {
                        hasError: false,
                        message: '',
                    };
                }
            }

            if (field === 'manifiesto') {
                const servicio = updatedRow.servicio;
                const isService = (
                    servicio !== "CB" &&
                    servicio !== "CI" &&
                    servicio !== "TI"
                );

                if (!value && isService) {
                    updatedErrors.manifiesto = {
                        hasError: true,
                        message: 'Dato requerido.',
                    };
                } else {
                    updatedErrors.manifiesto = {
                        hasError: false,
                        message: '',
                    };
                }
            }
            // Actualizar el objeto de errores en la fila
            updatedRow.errors = updatedErrors;
            // Actualizar la fila en los datos
            const newData = [...prevData];
            newData[rowIndex] = updatedRow;
            return newData;
        });
    }, [setData, rutaData, fInicio]);

    useEffect(() => {
        dataRef.current = data;
    }, [data]);

    useEffect(() => {
        table?.resetRowSelection();
    }, [data, table])

    return (
        <>
            <VirtualTable
                table={table}
                actionRefreshData={() => getData(clave, fInicio.current)}
                optionsTable={{ ofText: "Manifiestos de" }}
                isTableSelected={isTableSelected}
            />
        </>
    );
};

export default AltaManifiestosTable;
