import React, { useState } from "react";
import Logistica from "../../imagenes/alacenamiento-logistico.jpg";
import Preview from "../../imagenes/preview-login.jpg";
import { validarUsuario } from "../../Apis/FetchUsers";
import { useNavigate } from "react-router-dom";
import { setItem } from "../../utils/LocalStorage";
import CircleSpiner from "../../componentes/Spinners/CircleSpiner";
import CustomToastify from "../../componentes/Toasts/CustomToast";
import { InputText } from "../../componentes/Formik";
import { Formik, Form } from "formik";
import schemaCredenciales from "./SchemaLogin";
import ResetPasswordModal from "../../componentes/PopupRestablecer/ResetPasswordModal";
//import { useAppStore } from "../../Stores/useAppStore";

const Login = () => {
  const navigate = useNavigate();
  //const setUser = useAppStore(state => state.setUser);

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const initValuesAdd = {
    username: "",
    password: "",
  };

  async function onSubmitLogin(values) {
    const credenciales = {
      email: values.username,
      password: values.password,
    };

    setLoading(true);
    const response = await validarUsuario(credenciales);
    setLoading(false);

    if (response.headerResponse.statusOk && response.headerResponse.statusCode === 200) {
      //await setUser(values.username)
      const { bodyResponse } = response;
      setItem("user", bodyResponse);
      setItem("userName", bodyResponse.name ?? "");
      navigate("/suvalsa/home");
    } else {
      await mostrarMsjLogin(
        "Credenciales incorrectas. Por favor, verifica tu usuario y contraseña."
      );
    }
  }

  async function mostrarMsjLogin(msj) {
    CustomToastify({
      title: "Error de Autenticación",
      message: msj,
      type: "error",
    });
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="bg-gradient-to-r from-[#3366FF] to-[#6A00FF] h-screen flex justify-center items-center">
      {loading && <CircleSpiner />}
      <div className="flex flex-col lg:flex-row justify-center items-center w-full lg:px-8 gap-8">
        <div className="hidden lg:flex lg:w-1/2 h-full justify-center items-center px-4 lg:pl-8">
          <img
            className="object-cover lg:max-w-4xl rounded-lg shadow-lg"
            src={Preview}
            alt="Logistica Suvalsa"
          />
        </div>

        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-[0_4px_10px_rgba(0,0,0,0.1)] border border-[#E0E0E0]">
          <Formik
            initialValues={initValuesAdd}
            validationSchema={schemaCredenciales}
            onSubmit={onSubmitLogin}
          >
            {(props) => {
              const { errors, touched, isSubmitting } = props;
              return (
                <Form className="flex flex-col gap-6">
                  <h2 className="text-2xl font-bold text-center text-[#0044CC]">
                    INICIAR SESIÓN SUVALSA
                  </h2>
                  <div className="bg-indigo-300 rounded-lg overflow-hidden">
                    <img
                      className="object-cover h-48 w-full"
                      src={Logistica}
                      alt="Suvalsa"
                    />
                  </div>
                  <div className="flex flex-col gap-4">
                    <div>
                      <InputText
                        disabled={isSubmitting}
                        name="username"
                        type="email"
                        placeholder="Ingrese su correo"
                        className={`w-full border-[#D1E3FF] focus:ring-[#3366FF] focus:border-[#3366FF] rounded-lg ${errors.username && touched.username ? "border-red-500" : ""
                          }`}
                      />
                      {errors.username && touched.username && (
                        <div className="text-red-500 text-sm mt-1">{errors.username}</div>
                      )}
                    </div>

                    <div className="relative">
                      <InputText
                        disabled={isSubmitting}
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Ingrese su contraseña"
                        className={`w-full border-[#D1E3FF] focus:ring-[#3366FF] focus:border-[#3366FF] rounded-lg ${errors.password && touched.password ? "border-red-500" : ""
                          }`}
                      />
                      {errors.password && touched.password && (
                        <div className="text-red-500 text-sm mt-1">{errors.password}</div>
                      )}
                      <button
                        type="button"
                        onClick={() => setShowPassword(!showPassword)}
                        className="absolute inset-y-0 right-0 flex items-center pr-3 text-[#3366FF] hover:text-[#0044CC]"
                      >
                        {showPassword ? "Ocultar" : "Mostrar"}
                      </button>
                    </div>
                    <div>
                      <button
                        onClick={handleOpenModal}
                        className="text-[#4B9CE2] text-sm hover:text-[#0056D2] transition-colors"
                      >
                        Olvidé mi contraseña, quiero restablecerla
                      </button>
                      <ResetPasswordModal isOpen={isModalOpen} onClose={handleCloseModal} />
                    </div>
                  </div>

                  <div className="flex justify-start gap-4">
                    <button
                      disabled={isSubmitting}
                      type="submit"
                      className="w-full py-2 px-4 bg-[#3366FF] text-white font-semibold rounded-lg hover:bg-[#0044CC] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3366FF] transition-all"
                    >
                      Iniciar Sesión
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>

      <ResetPasswordModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default Login;
