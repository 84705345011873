// GenerarReporteButton.jsx
import React from 'react';
import CustomToastify from '../../../../../componentes/Toasts/CustomToast';

const GenerarReporteButton = ({ isSubmitting = false, data }) => {
    const handleGenerateReport = () => {
        if (!data || data.length === 0) {
            CustomToastify({
                title: "No hay datos",
                message: "No hay manifiestos emitidos para generar el reporte.",
                type: 'warning'
            });
            return;
        }

        // Implementa la lógica para generar el reporte
        // Por ejemplo, convertir los datos a CSV y descargar
        const csvContent = [
            ["Id", "Clave", "Ciudad", "Familia", "Nombre", "Ruta", "Orden", "Servicio", "Día", "Fecha visita", "Salida", "Manifiesto", "Planta", "Ruta Planta"],
            ...data.map(row => [
                row.id,
                row.clave,
                row.ciudad,
                row.familia,
                row.nombre,
                row.ruta,
                row.orden,
                row.servicio,
                row.dia,
                row.fvisita,
                row.salida,
                row.manifiesto,
                row.planta,
                row.rutaplanta
            ])
        ].map(e => e.join(",")).join("\n");

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute('download', 'ManifiestosEmitidos.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        CustomToastify({
            title: "Reporte Generado",
            message: "El reporte ha sido generado y descargado correctamente.",
            type: 'success'
        });
    };

    return (
        <button
            disabled={isSubmitting || data.length === 0}
            type="button" // Asegúrate de que el tipo es "button"
            className="btn-primary m-3 md:max-w-72"
            onClick={handleGenerateReport}
        >
            Generar reporte
        </button>
    );
}

export default GenerarReporteButton;
