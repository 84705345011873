import { useCallback, useEffect, useRef, useState } from "react";
import { Select } from "tw-elements";
import { createOption } from "../utils/CommonUtils";
import { ObtenerRutasPorPlanta } from "../Apis/FetchCatalogos";
import { BuscarClientePorFamilia } from "../Apis/FetchClientes";

const useRutaPlanta = ({selectRutaPlantaId = undefined, category =""}) => {
    const [gettingData, setGettingData] = useState(false);
    const [rutasPlanta, setRutasPlanta] = useState([]);
    const [plantasCat, setPlantasCat] = useState([]);
    const refRutaPlanta = useRef([])
    const refPlantasCat = useRef([])

    const agSelectOptPlantas= useCallback((rutas) => {
        return rutas !== null
            ? rutas.map((rutaP) =>
                createOption(rutaP.id, rutaP.value, rutaP.label))
            : "";
    }, [])

    useEffect(() => {
        if (selectRutaPlantaId) {
            const selectRutaP = document.querySelector("#" + selectRutaPlantaId);
            if (selectRutaP) Select.getOrCreateInstance(selectRutaP);
        }
        async function listarRutasP() {
            setGettingData(true);
            const listaRutasP = await ObtenerRutasPorPlanta();
            refRutaPlanta.current = listaRutasP
            setRutasPlanta(listaRutasP);
            setGettingData(false);
        }

        async function getRutaPlantasByCat() {
            setGettingData(true);
            const clPlanta = await BuscarClientePorFamilia(category);
            const newRutasP = clPlanta.map(rutaP => {
                return {
                    key: rutaP.id,
                    value: rutaP.clave,
                    label: rutaP.clave + ' ' + rutaP.nombre
                }
            })
            //refRutaPlanta.current = clPlanta
            refPlantasCat.current = newRutasP
            //setRutasPlanta(newRutasP);
            setPlantasCat(newRutasP)
            setGettingData(false);
        }

        if (rutasPlanta.length === 0 && category==="") listarRutasP();
        if (plantasCat.length === 0 && category!=="") getRutaPlantasByCat();

    }, []);

    return {
        rutasPlanta,
        plantasCat,
        refRutaPlanta,
        refPlantasCat,
        agSelectOptPlantas,
        gettingData
    }
}

export default useRutaPlanta