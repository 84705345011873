// Exportar el componente Filter correctamente
import React from 'react'
export default function Filter({ column, table }) {
    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
    const isText = column.columnDef.columnType === "text";
    const isNumeric = column.columnDef.columnType === "number";
    const isDate = column.columnDef.columnType === "date";
    const isSelect = column.columnDef.columnType === "select";
    const columnFilterValue = column.getFilterValue();
    return isSelect ? (
        <select
            value={columnFilterValue ?? ""}
            onChange={(e) => {
                const value = e.target.value;
                column.setFilterValue(value || undefined);
            }}
            className="border shadow rounded p-1 bg-white text-gray-400"
        >
            <option value="">Buscar</option>
            {column.columnDef.selectOptions?.map(option => (
                <option key={option.key} value={option.value} className="text-black">
                    {option.label}
                </option>
            ))}
        </select>
    ) : (
        <input
            type={isNumeric ? "number" : isDate ? 'date' : isText ? "text" :  (typeof firstValue)}
            value={columnFilterValue ?? ""}
            onChange={(e) => {
                const value = e.target.value;
                column.setFilterValue(value || undefined);
            }}
            placeholder={`Buscar...`}
            className="w-full border shadow rounded p-1 bg-white text-gray-400"
        />

    );
}