import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logoSCT from "../../imagenes/sct.png"; // Logotipo de SCT
import logoSuvalsa from "../../imagenes/sct.png"; // Logotipo de SUVALSA

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 8,
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
    },
    headerContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15,
    },
    logo: {
        width: 150,
        height: 50,
    },
    centralHeader: {
        textAlign: 'center',
        flex: 1, // Se asegura que ocupe el espacio central
    },
    headerTitle: {
        fontSize: 10,
        fontWeight: 'bold',
    },
    headerSubtitle: {
        fontSize: 8,
        marginTop: 3,
    },
    address: {
        fontSize: 7,
        marginTop: 5,
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: '#000',
        marginVertical: 5,
    }, table: {
        display: 'table',
        width: '100%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#000',
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableHeader: {
        backgroundColor: '#FFD700', // Amarillo
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 8,
        borderRightWidth: 1,
        borderRightColor: '#000',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        padding: 2,
    },
    tableCol: {
        borderRightWidth: 1,
        borderRightColor: '#000',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        textAlign: 'center',
        fontSize: 7,
        padding: 2,
    },
    tableColWide: {
        flex: 3, // Espacio de 3 celdas
        borderRightWidth: 1,
        borderRightColor: '#000',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        textAlign: 'center',
        fontSize: 7,
        padding: 2,
    },
    tableColNarrow: {
        flex: 1,
        borderRightWidth: 1,
        borderRightColor: '#000',
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        textAlign: 'center',
        fontSize: 7,
        padding: 2,
    },
    lastCol: {
        borderRightWidth: 0,
    },
});

const BitacoraHorasServicio = ({ data }) => {
    const {
        conductor,
        vehiculo,
        placas,
        auxiliar,
        origen,
        destino,
        fInicio,
        fechaFin,
        fecha,
        nombreconductor,
        nomanifiestos,
    } = data;

    return (
        <Document>
            <Page size="Letter" orientation="landscape" style={styles.page}>
                {/* Encabezado */}
                <View style={styles.headerContainer}>
                    <Image style={styles.logo} src={logoSCT} />
                    <View style={styles.centralHeader}>
                        <Text style={styles.headerTitle}>Bitácora de Horas de Servicio del Conductor<Text style={{ fontSize: 4 }}>Nom-087-SCT-2-2017</Text></Text>
                        <Text style={styles.headerSubtitle}>SUVALSA, S.A DE C.V</Text>
                        <Text style={styles.address}>
                            Jose Vasconcelos 39 Edif 1 Depto 4 Planta Baja, Manzana D,  Col. Santisima Trinidad, Tlalpan,
                            Ciudad de México, CP 14429
                            | Tel: (55) 54852800 | (744) 4508813
                        </Text>
                    </View>
                </View>


                {/* Información del conductor y viaje */}
                <View style={{ border: '1px solid black' }}>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.headerSubtitle}>
                                Nombre del Conductor: {nombreconductor}
                            </Text>
                            <Text style={styles.headerSubtitle}>Nombre del conductor y/o PErsonal Auxiliar: {auxiliar}</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.headerSubtitle}>Tipo de Vehiculo: {vehiculo} |                Placas: {placas}</Text>
                            <Text style={styles.headerSubtitle}>
                                No. de Licencia (conductorAbr) Licencia "E"
                            </Text>
                        </View>
                    </View>
                </View>

                <View>
                    <View style={{ display: 'flex', flexDirection: 'row' }}>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.headerSubtitle}>
                                Origen Viaje: {origen}           | Destino: {destino}
                            </Text>
                            <Text style={styles.headerSubtitle}>Fecha de inicio: {fecha}                 Fecha de Final: {fInicio} </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={styles.headerSubtitle}>Carta Porte y/o Documentos de Embarque No. {nomanifiestos}manifiestos</Text>
                            <Text style={styles.headerSubtitle}>
                                Actividad
                            </Text>
                        </View>
                    </View>
                </View>

                {/* Tabla de actividades */}
                <View style={styles.table}>
                    {/* Fila de encabezado principal */}
                    <View style={styles.tableRow}>
                        <Text style={[styles.tableHeader, { flex: 1 }]}>DIA</Text>
                        <Text style={[styles.tableHeader, { flex: 2, fontSize: 5 }]}>ACTIVIDAD / HORA</Text>
                        {[...Array(24)].map((_, i) => (
                            <Text key={i} style={[styles.tableHeader, { flex: 1 }]}>
                                {i + 1}
                            </Text>
                        ))}
                        <Text style={[styles.tableHeader, { flex: 3 }]}>Hrs por Día</Text>
                        <Text style={[styles.tableHeader, { flex: 3 }]}>Kms por Día</Text>
                        <Text style={[styles.tableHeader, styles.lastCol, { flex: 5 }]}>
                            <Text style={{ fontSize: 4 }}>
                                Excepciones (Avería, Bloqueo, Tráfico, Perturbación, Fuerza Mayor)
                            </Text>
                        </Text>
                    </View>

                    {/* Fila de datos replicada 7 veces */}
                    {Array.from({ length: 7 }).map((_, dayIndex) => (
                        ['Fuera de Servicio', 'Conduciendo', 'Descanso'].map((actividad, idx) => (
                            <View style={styles.tableRow} key={`${dayIndex}-${idx}`}>
                                <Text style={[styles.tableCol, { flex: 1 }]}></Text>
                                <Text style={[styles.tableColWide, { flex: 2 }]}>{actividad}</Text>
                                {[...Array(24)].map((_, i) => (
                                    <Text key={i} style={[styles.tableCol, { flex: 1 }]}></Text>

                                ))}
                                <Text style={[styles.tableCol, { flex: 3 }]}></Text>
                                <Text style={[styles.tableCol, { flex: 3 }]}></Text>
                                <Text style={[styles.tableCol, styles.lastCol, { flex: 5 }]}></Text>
                            </View>
                        ))
                    ))}
                </View>
                <View style={{ marginTop: 10, borderTopWidth: 1, borderColor: '#000' }}>
                    {/* Tabla para "Ruta Puntos más importantes de su Trayecto" */}
                    <View style={{
                        flexDirection: 'row',
                        borderWidth: 1,
                        borderColor: '#000',
                        marginBottom: 2,
                        padding: 10,
                    }}>
                        <Text style={{ fontSize: 7, textAlign: 'left', flex: 1, paddingLeft: 5 }}>
                            Ruta Puntos más importantes de su Trayecto
                        </Text>
                    </View>

                    {/* Contenedor de las firmas */}
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                        {/* Firma del conductor */}
                        <View style={{ flex: 1, alignItems: 'center' }}>
                            <View style={{ borderTopWidth: 1, borderColor: '#000', width: '80%' }} />
                            <Text style={{ fontSize: 7, marginTop: 2 }}>Firma del Conductor</Text>
                        </View>

                        {/* Firma del conductor auxiliar */}
                        <View style={{ flex: 1, alignItems: 'center' }}>
                            <View style={{ borderTopWidth: 1, borderColor: '#000', width: '80%' }} />
                            <Text style={{ fontSize: 7, marginTop: 2 }}>
                                Firma del Conductor Auxiliar y/o Personal de Apoyo
                            </Text>
                        </View>

                        {/* Firma del representante */}
                        <View style={{ flex: 1, alignItems: 'center' }}>
                            <View style={{ borderTopWidth: 1, borderColor: '#000', width: '80%' }} />
                            <Text style={{ fontSize: 7, marginTop: 2 }}>
                                Firma del Representante Permisionario
                            </Text>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default BitacoraHorasServicio;