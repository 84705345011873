import React, { memo } from 'react';

// Componente Select reutilizable
export const MemoSelect = memo(function SelectComponent({ options, value, onChange, className = "", onKeyDown }) {
  return (
    <select
      value={value || ''}
      onChange={(e) => {
        const selectedOption = options.find(option => option.value === e.target.value);
        onChange(selectedOption ? selectedOption.value : '');
      }}
      className={`border rounded ${className}`}
      onKeyDown={onKeyDown}
    >
      <option value="">Seleccionar</option>
      {options.map(option => (
        <option key={option.key} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
});
MemoSelect.displayName = 'MemoSelect';