// VirtualTable.jsx
import React, { memo, useRef } from "react";
import { flexRender } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual"; // Actualizado
import "./virtualTable.css";
import Filter from "./Filter";

const Table = ({
    table,
    actionRefreshData,
    optionsTable,
    isTableSelected = false,
    columnsHidden = [],
}) => {
    const parentRef = useRef();
    const rowSize = 55
    // Configuración de la virtualización con useVirtualizer
    const rowVirtualizer = useVirtualizer({
        count: table.getRowModel().rows.length, // Número total de filas
        getScrollElement: () => parentRef.current, // Elemento que maneja el scroll
        estimateSize: () => rowSize, // Altura estimada de cada fila
        overscan: 10, // Número de filas adicionales a renderizar fuera de la vista
    });

    const virtualRows = rowVirtualizer.getVirtualItems();
    const totalSize = rowVirtualizer.getTotalSize();

    let contFrozen = 1;

    // Función para obtener estilos comunes de pinning
    const getCommonPinningStyles = ({ column, isColumn, isPair }) => {
        const isSelect = column.id === "select";
        // Determinar si la columna es freezeable
        const isFrozen = column.columnDef.isFrozen;

        // Definir estilos de posición
        const positionStyle = isSelect || isFrozen ? "sticky" : "relative";

        // Definir zIndex para columnas fijas
        const zIndexStyle = isSelect ? 1 : isFrozen ? contFrozen : 0;
        //controlar el contador de las columnaFrozen
        if (column.getIsLastColumn()) contFrozen = 1;

        // Definir left para columnas fijas
        const leftStyle = //isFrozen ? `${column.getStart()}px` : undefined;
            isSelect
                ? `${column.getStart("left")}px`
                : isFrozen && isTableSelected
                    ? (contFrozen * 45) + 'px'
                    : isFrozen ? `${column.getStart()}px` : undefined

        //cada que viene un columna Frozen se aumenta para ir aplicando el left a la siguiente columna
        if (column.columnDef.isFrozen) contFrozen++;

        // Determinar si la columna está oculta
        const isHidden = columnsHidden.length > 0 ? columnsHidden.includes(column.id) : false;

        const minWidth = isSelect
            ? "50px"
            : column.getSize().toString().concat("px")

        return {
            position: positionStyle,
            left: leftStyle,
            zIndex: zIndexStyle,
            width: minWidth,//`${column.getSize()}px`,
            minWidth: minWidth,//`${column.getSize()}px`,
            maxWidth: undefined,//`${column.getSize()}px`,
            backgroundColor: isColumn ? "#D1E3FF" :
                isPair ? "#F4F6F8" : isSelect || isFrozen ? "white" : 'transparent',
            textAlign: "left", // Ajustar según necesidad
            // padding: "0px 4px", // Agregar padding
            display: isHidden ? 'none' : ''
        }
    };

    // Función para renderizar los íconos de ordenación personalizados
    const renderSortIcons = (column) => {
        if (!column.getIsSorted()) {
            return null;
        }

        if (column.getIsSorted() === "asc") {
            return (
                <span
                    className="sort-icon"
                    style={{ transform: "rotate(180deg)" }}
                >
                    {/* SVG para orden ascendente */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        strokeWidth="3"
                        stroke="currentColor"
                        className="w-4 h-4 inline"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                        ></path>
                    </svg>
                </span>
            );
        } else if (column.getIsSorted() === "desc") {
            return (
                <span
                    className="sort-icon"
                    style={{ transform: "rotate(0deg)" }}
                >
                    {/* SVG para orden descendente */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 24 24"
                        strokeWidth="3"
                        stroke="currentColor"
                        className="w-4 h-4"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18"
                        ></path>
                    </svg>
                </span>
            );
        }
        return null;
    };

    return (
        <>
            <div
                className={optionsTable?.classNames || "table-container"}
                style={{ ...optionsTable?.styles }}
            >
                {/* Cuerpo virtualizado de la tabla */}
                <div
                    ref={parentRef}
                    className="body-virtualizer"
                >
                    <table className="body-table"
                        style={{
                            height: `${totalSize+100}px`, // Altura del contenedor del tbody
                            overflow: "auto",
                        }}>
                        {/* Encabezados de la tabla */}
                        <thead>
                            {table.getHeaderGroups().map((headerGroup) => (
                                <React.Fragment key={headerGroup.id}>
                                    <tr>
                                        {headerGroup.headers.map((header) => (
                                            <th
                                                key={header.id}
                                                colSpan={header.colSpan}
                                                style={getCommonPinningStyles({
                                                    column: header.column,
                                                    isColumn: true,
                                                })}
                                                className="pb-0"
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div
                                                        className={
                                                            header.column.getCanSort()
                                                                ? "cursor-pointer select-none flex items-center group"
                                                                : "flex items-center"
                                                        }
                                                        onClick={header.column.getToggleSortingHandler()}
                                                        title={
                                                            header.column.getCanSort()
                                                                ? header.column.getIsSorted()
                                                                    ? `Orden ${header.column.getIsSorted() === "asc" ? "descendente" : "ascendente"}`
                                                                    : "Ordenar"
                                                                : undefined
                                                        }
                                                    >
                                                        <div className="flex items-center">
                                                            {flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                            {/* Iconos de ordenación personalizados */}
                                                            {renderSortIcons(header.column)}
                                                        </div>
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                    <tr>
                                        {headerGroup.headers.map((header, colIndex) => (
                                            <th
                                                key={`${header.id}-filter`}
                                                colSpan={header.colSpan}
                                                style={getCommonPinningStyles({
                                                    column: header.column,
                                                    isColumn: true,
                                                    count: 0
                                                })}
                                            >
                                                {header.isPlaceholder ? null : (
                                                    <div className="mt-2">
                                                        {header.column.getCanFilter() ? (
                                                            <Filter column={header.column} table={table} />
                                                        ) : null}
                                                    </div>
                                                )}
                                            </th>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            ))}
                        </thead>
                        <tbody className="divRows">
                            {/* Espacio total de la lista virtualizada */}
                            {virtualRows.map((virtualRow) => {
                                const row = table.getRowModel().rows[virtualRow.index];
                                return (
                                    <tr
                                        key={row.id}
                                        ref={virtualRow.measureRef}
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: `${rowSize}px`,
                                            transform: `translateY(${virtualRow.start}px)`,
                                        }}
                                    >
                                        {row.getVisibleCells().map((cell, colIndex) => (
                                            <td
                                                key={cell.id}
                                                style={getCommonPinningStyles({
                                                    column: cell.column,
                                                    isColumn: false,
                                                    isPair: virtualRow.index % 2 === 1,
                                                })}
                                            >
                                                <div data-row={virtualRow.index} data-col={colIndex}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Paginación y otras opciones */}
            <div className="flex md:flex-row justify-end items-center space-x-4 text-sm flex-col leading-[1.6] border-neutral-200 dark:border-neutral-500 bg-transparent dark:bg-neutral-800">
                <div className="flex items-center space-x-4 order-3 md:order-none">
                    {actionRefreshData && (
                        <button
                            type="button"
                            onClick={actionRefreshData}
                            style={{ fontSize: "24px" }}
                            className="btn-refresh"
                        >
                            {/* SVG del botón de refresh */}
                            <svg
                                fill="#706e6efc"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-6 h-6"
                                viewBox="0 0 383.748 383.748"
                            >
                                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                                <g
                                    id="SVGRepo_tracerCarrier"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                    <g>
                                        <path d="M62.772,95.042C90.904,54.899,137.496,30,187.343,30c83.743,0,151.874,68.13,151.874,151.874h30 C369.217,81.588,287.629,0,187.343,0c-35.038,0-69.061,9.989-98.391,28.888C70.368,40.862,54.245,56.032,41.221,73.593 L2.081,34.641v113.365h113.91L62.772,95.042z"></path>
                                        <path d="M381.667,235.742h-113.91l53.219,52.965c-28.132,40.142-74.724,65.042-124.571,65.042 c-83.744,0-151.874-68.13-151.874-151.874h-30c0,100.286,81.588,181.874,181.874,181.874c35.038,0,69.062-9.989,98.391-28.888 c18.584-11.975,34.707-27.145,47.731-44.706l39.139,38.952V235.742z"></path>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    )}
                    <span className="font-light">Registros por página</span>
                    <div className="w-[70px]">
                        <select
                            style={{ WebkitAppearance: "none" }}
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value));
                            }}
                            className="border rounded p-1"
                        >
                            {[1000, 1250, 1500, 3000, 5000].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    {pageSize}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="font-normal order-2 mb-3 md:order-none md:mb-0 ">
                    <p>
                        {table.getState().pagination.pageIndex * table.getState().pagination.pageSize + 1}{" - "}
                        {Math.min(
                            (table.getState().pagination.pageIndex + 1) * table.getState().pagination.pageSize,
                            table.getRowModel().rows.length
                        )}{" "}
                        {optionsTable?.ofText || " Registros de "} {table.getRowCount()}
                    </p>
                </div>
                <div className="order-1 my-3 md:order-none md:my-0 md:pr-1 w-full md:w-max justify-around flex md:block">
                    <button
                        type="button"
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                        className="button-pagination"
                    >
                        {/* SVG del botón de primera página */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.75 20.5L1.25 12l7.5-7.5"
                            ></path>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5"
                            ></path>
                        </svg>
                    </button>
                    <button
                        type="button"
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className="button-pagination"
                    >
                        {/* SVG del botón de página anterior */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.75 19.5L8.25 12l7.5-7.5"
                            ></path>
                        </svg>
                    </button>
                    <button
                        type="button"
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className="button-pagination"
                    >
                        {/* SVG del botón de siguiente página */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            ></path>
                        </svg>
                    </button>
                    <button
                        type="button"
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
                        disabled={!table.getCanNextPage()}
                        className="button-pagination"
                    >
                        {/* SVG del botón de última página */}
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-4 h-4"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.25 4.5l7.5 7.5-7.5 7.5"
                            ></path>
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M8.25 4.5l7.5 7.5-7.5 7.5"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </>
    )
};

export default memo(Table);
