import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logoPlantilla from "../../imagenes/logo_plantilla.png";

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    headerContainer: {
        position: 'absolute',
        top: 20,
        left: 20,
        right: 20,
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 50,
        height: 50,
        marginRight: 10,
    },
    footer: {
        position: 'absolute',
        bottom: 20,
        left: 20,
        right: 20,
        fontSize: 10,
        textAlign: 'center',
    },
    content: {
        marginTop: 55,
        marginBottom: 110,
        lineHeight: 1.5,
    },
    section: {
        marginBottom: 10,
    },
    list: {
        paddingLeft: 20,
    },
    smallText: {
        fontSize: 8,
    },
    signature: {
        marginTop: 20,
        lineHeight: 2,
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: 'black',
        marginVertical: 10,
    },
});

const CartaResponsiva = ({ data }) => { // Accede a data correctamente desde props
    console.log('Datos recibidos:', data); // Verifica que los datos lleguen correctamente

    const { ruta, fecha, fInicio, nombre, duracion, operador, auxiliar, vehiculo } = data || {};
    return (
        <Document>
            <Page size="Letter" orientation="portrait" style={styles.page}>
                <View style={styles.headerContainer} fixed>
                    <View style={styles.headerLeft}>
                        <Image style={styles.logo} src={logoPlantilla} />
                        <View>
                            <Text style={styles.smallText}>
                                Jose Vasconcelos 39 Edif 1 Depto 4 Planta Baja, Manzana D Col. Santisima Trinidad, Tlalpan,
                                Ciudad de Mexico, 14429, 01-55 54 85 28 00
                            </Text>
                            <Text style={styles.smallText}>
                                Carretera Al Arenal Km 1.5 Sin Número El Bejuco Acapulco, Gro., Cp 39931, 01-744-450 88 13,
                                100 3672
                            </Text>
                        </View>
                    </View>
                </View>



                <View style={styles.content}>
                    <View style={{ textAlign: 'center', marginBottom: 20 }}>
                        <Text style={{ fontSize: 14, fontWeight: 'bold' }}>Carta Responsiva </Text>
                    </View>
                    <View style={styles.section}>
                        <Text>
                            Por medio de la presente, certifico que el vehículo de marca: , modelo: , año: , con placas: , No. Económico: {vehiculo}, me ha sido entregado en perfecto estado operativo...
                        </Text>
                    </View>

                    <View style={styles.section}>
                        <Text>En este mismo acto, recibo la documentación certificada que avala el ramo a que se dedica esta unidad, como son:</Text>
                        <View style={styles.list}>
                            <Text>- Tarjeta de Circulación</Text>
                            <Text>- Autorización para circular en horarios restringidos</Text>
                            <Text>- Permiso SCT</Text>
                            <Text>- Permiso INE</Text>
                            <Text>- Verificación Vehicular</Text>
                            <Text>- Tenencia</Text>
                            <Text>- Póliza del Seguro</Text>
                            <Text>- Manual de Contingencia</Text>
                            <Text>- Copia de la Norma Oficial NOM-087-ECOL-SSA 1-2002</Text>
                            <Text>- Bitácora de Ruta</Text>
                            <Text>- Bitácora de Horas de Servicio del Operador</Text>
                            <Text>- Manifiestos de Recolección</Text>
                            <Text>- Formato de Gastos</Text>
                            <Text>- Formato de Revisión Diaria de Condiciones Físico-Mecánico de la Unidad</Text>
                            <Text>- Manifiesto para caso de derrames</Text>
                            <Text>- Hoja de Seguridad en Emergencias</Text>
                            <Text>- Formato de Control de Remanentes</Text>
                        </View>
                    </View>

                    <View style={styles.section}>
                        <Text>
                            Nosotros, bajo protesta de decir verdad, nos comprometemos a respetar y cumplir con lo
                            establecido en la Ley de Caminos, Puentes y Auto Transporte Federal, el Reglamento de Tránsito
                            en Carreteras Federales, el Reglamento para el Transporte Terrestre de Materiales y Residuos
                            Peligrosos, así como las Leyes y Reglamentos de Jurisdicción Estatal y Municipal, y con la Norma
                            Oficial NOM-087-ECOL-SSA 1-2002 en Materia Vigente, a fin de mantener el uso adecuado de las
                            unidades tanto en lo mecánico como operativo en los ámbitos local y federal.
                        </Text>
                        <Text style={{ marginTop: 10 }}>
                            Así mismo declaro que no consumo ningún tipo de droga ni de bebida embriagante, ni pastillas
                            para no dormir o cualquier otro elemento que altere mi estado físico y mental.
                        </Text>
                        <Text style={{ marginTop: 10 }}>
                            En caso de incumplimiento seré totalmente responsable, tanto en lo legal y moral como en lo
                            económico de las sanciones a que me haya hecho acreedor.
                        </Text>
                    </View>

                    <View style={styles.signature}>
                        <Text>La presente responsiva la firmamos a los _____ días del mes de ____________________ de __________</Text>
                        <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                            <View style={{ flex: 1, marginRight: 10 }}>
                                <Text>Operador Principal ({operador}) Licencia "E" </Text>
                                <Text>Nombre_________________________________</Text>
                                <Text>Firma __________________________________</Text>
                            </View>
                            <View style={{ flex: 1, marginLeft: 10 }}>
                                <Text>Personal de Apoyo</Text>
                                <Text>Auxiliar({auxiliar})</Text>
                                <Text>Nombre_________________________________</Text>
                                <Text>Firma __________________________________</Text>
                            </View>
                        </View>
                    </View>
                </View>


            </Page>
        </Document>
    );
};

export default CartaResponsiva;