import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
// Initialization for ES Users
import { Modal, initTE, Select } from "tw-elements";

import { InputText, SelectFile } from '../../../../componentes/Formik';
import CircleSpiner from '../../../../componentes/Spinners/CircleSpiner';
import { ButtonGroup } from '../../../../componentes/Button/GenericButton';
import Accordion from '../../../../componentes/Accordion/Accordion';
import schemaAltaSalidaAlmacen from '../../Schemas/SchemaAltaSalidaAlmacen';
import useAlmacenAlta from '../../useAlmacen'
import useActualizarRuta from '../../../Rutas/Actualizar/UseActualizarRuta';
import CustomModal from '../../../../componentes/Modals/CustomModal';
import InsumosTable from '../../../Catalogos/Insumos/components/table/InsumosTable';
import CustomToastify from '../../../../componentes/Toasts/CustomToast';
import { CustomInputText } from '../../../../componentes/Formik/InputText';
import { CustomGenericTable } from '../../../../componentes/Tables/CustomTable';

export default function AltaSalidaAlmacenForm() {

  const [clave, setClave] = useState();
  const [flagExpanded, setFlagExpanded] = useState("false");
  const [flagSearch, setFlagSearch] = useState(false);

  const {
    loading,
    loadingData,
    rutaData,
    resetRutaData,
    dataFounded,
    BuscarRuta,
  } = useActualizarRuta();

  const {
    loadingAction,
    ObtenerClientesSinSalida,
    dataManifests,
    setDataManifests,
    onSaveSal,
    addSelectTipo,
    dataRowsInsumos,
    dataRowsClients,
    setDataRowsClients,
    setDataRowsInsumos,
    idModalSelectClients,
    setCustomColumnFiltersClients,
    columnCustomFiltersClients,
    setRowSelectionClientes,
    rowSelectionClientes,
    opcionesTablaClientes,
    columnasClientes,
  } = useAlmacenAlta();

  const initValuesAdd = {
    manif: "",
    fInicio: "",
    tipo: "",
    ruta: "",
    nombreRuta: "",
    referencia: "",
  };

  const onChangeRuta = async (clave, fInicio, setFieldValue, isValid) => {

    setFieldValue("ruta", clave);
    //siempre que no se tenga la clave se mantiene deshabilitada las opciones de botones y demas componentes
    //y se resetean los valores que dependen de la data
    if (clave === "") {
      setFieldValue("nombreRuta", "");
      resetRutaData()
    }

    if (!isValid) {
      setFlagSearch(false)
      setFlagExpanded("false")
      return
    }
    //se obtienen los manifiestos asociados a la ruta y fecha, internamente ya se almacenan en el hook dataManifests
    const manifiestos = await ObtenerClientesSinSalida(clave, fInicio)
    let data = { id: "", nombre: "" };

    if (manifiestos.clientes.length > 0) {
      data = await BuscarRuta(clave)// BuscarRutaPorFecha(clave, fInicio.current);
    } else {
      const msg = manifiestos.existen ? `La ruta ${clave} ya existe para el ${fInicio}` : `La ruta ${clave} no esta registrada para el ${fInicio}`

      CustomToastify({
        title: manifiestos.existen ? "Ruta encontrada" : "Ruta no encontrada", message: msg, type: 'warning'
      })
    }
    setFieldValue("nombreRuta", data.nombre);
    //si se captura una clave y la consulta de la ruta si regresa info, se expande el acordion, si no, se oculta
    if (clave !== "" && data.id !== "") {
      setFlagExpanded("true")
    }
    else {
      setFlagExpanded("false")
    }
    setFlagSearch(true)
  };

  // Definición de los botones del grupo de acciones
  const buttonsGroup = {
    buttonPrimary: {
      disabled:
        loading ||
        dataRowsInsumos.length <= 0,
      type: "submit",
      label: "Guardar"
    },
    buttonSecondary: {
      action: actionShowClientes,
      disabled: loading || !dataFounded,
      type: "button",
      label: "Seleccionar Clientes"
    },
  };

  function actionShowClientes() {
    const myModal = new Modal(document.getElementById(idModalSelectClients));
    myModal.show();
  }

  async function onSubmitSave(values) {
    if (dataRowsClients.length <= 0) {
      actionShowClientes();
      return
    }
    await onSaveSal(values);
  }

  useEffect(() => {

    const selectTipo = document.querySelector("#selectTipo");
    if (selectTipo) Select.getOrCreateInstance(selectTipo);

    initTE({ Modal });
  }, []);

  return (
    <>
      {(loadingData || loadingAction) && <CircleSpiner />}
      <Formik
        initialValues={initValuesAdd}
        validationSchema={schemaAltaSalidaAlmacen}
        onSubmit={onSubmitSave}
      >
        {(props) => {
          const { values, isSubmitting, setFieldValue, handleChange, isValid, setFieldTouched, validateForm } = props;

          return (
            <Form className="flex flex-col w-full p-2 gap-2">
              <Accordion
                titulo="Datos para la salida"
                idTarget="collapseAltaSalidasVForm"
                expanded="true"
              >
                <div className=" grid grid-cols-1 md:grid-cols-4 xl:grid-cols-5 col-start-2 items-start gap-2 w-full">
                  <InputText
                    disabled={isSubmitting || loadingData}
                    value={clave}
                    label="R+No. de la Ruta"
                    name="ruta"
                    type="number"
                    placeholder=""
                    onBlur={async (e) => {
                      validateForm().then(async () => {
                        await onChangeRuta(e.target.value, values.fInicio, setFieldValue, isValid)

                      }
                      )
                    }}
                    onKeyDown={async (e) => {
                      //presiona enter o tab
                      if (e.keyCode === 13 || e.keyCode === 9) {
                        await onChangeRuta(e.target.value, values.fInicio, setFieldValue, isValid);
                      }
                    }}
                    onChange={async (e) => {
                      setClave(e.target.value)
                    }}
                  />
                  <InputText
                    disabled={loadingData}
                    label="Fecha de Inicio"
                    type="date"
                    placeholder=""
                    name="fInicio"
                    onChange={async (e) => {
                      handleChange(e);
                      if (!clave) return;
                      //si ya se buscaron y encontraron los datos de la ruta, esto es con el servicio de BuscarRutaClave
                      //ya no se realiza la busqueda de nuevo, ya que solo se basa en la clave que es la ruta
                      //if(dataFounded) return
                      await setFieldTouched("fInicio");
                      validateForm().then(async () => {

                        await onChangeRuta(clave, e.target.value, setFieldValue, isValid);
                      })
                    }}
                  />
                  <SelectFile
                    id="selectTipo"
                    disabled={isSubmitting || loadingData}
                    className="pt-[0.1235rem]"
                    data-te-select-init
                    label="Tipo"
                    name="tipo"
                    validateOnChange
                    onChangeAction={async (e) => {
                      handleChange(e);
                      if (!clave || flagSearch) return;

                      validateForm().then(async () => {
                        setFieldTouched("tipo");
                        //si ya se buscaron y encontraron los datos de la ruta, esto es con el servicio de BuscarRutaClave
                        //ya no se realiza la busqueda de nuevo, ya que solo se basa en la clave que es la ruta
                        //if(dataFounded) return
                        await onChangeRuta(clave, values.fInicio, setFieldValue, isValid);
                      })
                    }}
                  >
                    <option hidden defaultValue value=""></option>
                    {addSelectTipo()}
                  </SelectFile>
                  <InputText
                    disabled={true}
                    label="Nombre"
                    name="nombreRuta"
                    type="text"
                    placeholder=""
                  />
                  <InputText
                    disabled={false}
                    label="Referencia"
                    name="referencia"
                    type="text"
                    placeholder=""
                    onBlur={async () => {
                      if (!clave || flagSearch) return;

                      setFieldTouched("referencia");
                      //si ya se buscaron y encontraron los datos de la ruta, esto es con el servicio de BuscarRutaClave
                      //ya no se realiza la busqueda de nuevo, ya que solo se basa en la clave que es la ruta
                      await onChangeRuta(clave, values.fInicio, setFieldValue, isValid);
                    }}
                  />

                  <button
                    disabled={isSubmitting || !(clave && clave !== '')}
                    type="button"
                    className="btn-secondary m-3 md:mt-2"
                    onClick={async () => {
                      await onChangeRuta(clave, values.fInicio, setFieldValue, isValid);
                    }}
                  >
                    Buscar
                  </button>
                </div>
              </Accordion>
              <Accordion
                titulo={"Lista de Insumos"}
                idTarget="collapseInsumos"
                expanded={flagExpanded}
                disabled={!dataFounded}
              >
                {
                  flagExpanded === "true" ? (
                    <InsumosTable isEditable={true} isSelected={true} multiSelected={true} setDataRowSelection={setDataRowsInsumos} />
                  ) : <></>
                }
              </Accordion>
              <CustomModal
                id={idModalSelectClients}
                title={`Agregar Clientes - Ruta ${rutaData?.clave ?? ""}`}
                withForm
              >
                <div className="flex flex-col w-full gap-2">
                  <div className="flex flex-col w-full gap-2">
                    {/* <ClientesRutaTable
                      clave={clave}
                      autoLoad={loadClients}
                      isSelected={true}
                      multiSelected={true}
                      setDataRowSelection={setDataRowsClients} /> */}
                    <div className="w-full md:w-1/4">
                      <CustomInputText
                        label="Buscar por clave"
                        name="claveSearch"
                        type="number"
                        placeholder=""
                        onChange={async (e) => {
                          setCustomColumnFiltersClients([
                            {
                              id: "clave",
                              value: e.target.value
                            }
                          ]);
                        }}
                      />
                    </div>
                    <CustomGenericTable
                      data={dataManifests}
                      setData={setDataManifests}
                      getData={() => { setRowSelectionClientes({}) }}
                      optionsTable={opcionesTablaClientes}
                      columnsData={columnasClientes}
                      //defaultColumn={isEditable ? defaultColumnInsumos : {}}
                      columnCustomFilters={columnCustomFiltersClients}
                      isTableSelected={true}
                      isMultiRowSelection={true}
                      rowSelection={rowSelectionClientes}
                      setRowSelection={setRowSelectionClientes}
                      setDataRowSelection={setDataRowsClients}
                    />
                  </div>
                  <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
                    <div className="w-full grid flex-row md:flex md:flex-row-reverse md:justify-start gap-4">
                      {/* <span data-twe-modal-dismiss>
            </span> */}
                      <button
                        type="button"
                        className="btn-secondary"
                        data-te-modal-dismiss
                      >
                        Cerrar
                      </button>
                    </div>
                  </div>
                </div>
              </CustomModal>
              <div className="h-2" />
              <ButtonGroup
                buttonPrimary={buttonsGroup.buttonPrimary}
                buttonSecondary={buttonsGroup.buttonSecondary}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
