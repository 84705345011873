import React, { useEffect, useMemo, useState } from "react";
// Initialization for ES Users
import { Modal, initTE } from "tw-elements";
import { ActualizarInsumo, ObtenerInsumos } from "../../../Apis/FetchInsumos";
import { CrearSalida, ObtenerMaxSalida } from "../../../Apis/FetchSalidas";
import { CustomGenericTable } from "../../../componentes/Tables/CustomTable";
import CircleSpiner from "../../../componentes/Spinners/CircleSpiner";
import {
  Generarmanifiesto,
  ObtenerMaxManifiesto
} from "../../../Apis/FetchManifiestos";
import { useExecutePromise } from "../../../Hooks/UsePromiseAction";
import dayjs from "dayjs";
//import CustomToastify from "../../../componentes/Toasts/CustomToast";
import { columnSize } from "../../../Constantes";

const AgregarInsumoForm = (props) => {
  const msjErrorQuantity = 'La cantidad debe ser mayor a 0';
  const [loading, setLoading] = useState(false);
  const [insumos, setInsumos] = useState([]);
  const opcionesTabla = { ofText: "Insumos de" };

  const columnasData = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: false
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "cantidad",
        header: "cantidad",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "existencias",
        header: "Existencias",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "modelo",
        header: "Modelo",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "unidad",
        header: "Uni",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "tipo",
        header: "Tipo",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const TableCell = (getValue, row, id, table) => {
    const [value, setValue] = useState("");
    const [onError, setOnError] = useState(false);
    let initialValue = getValue();

    const isCantidad = id === "cantidad";
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      table.options.meta?.updateData(row.index, id, value);
    };
    //establecer el valor de inicio, cuando se recuperan del servicio
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    //dependiendo de la validacion se setea como input o no
    return isCantidad ? (
      <>
                <input
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        listenerValidaNumber(e, setOnError);
                    }}
                    onBlur={onBlur}
                    type={"number"}
                    className={`${onError ? 'dataError' : ''}`}
                />
                {
                    onError ? 
                        <span className="dataError-Msj">{msjErrorQuantity}</span>
                    : ''
                }
            </>
    ) : (
      <span>{value}</span>
    );
  };

  /**
  * Funcion que valida si el numero ingresado es correcto, establece formato para la celda y Flag del mensaje de error
  * @param {*} e evento onValueChange
  * @param {*} setOnError hook para controlar la bandera de error sobre la celda o campo
  */
 const listenerValidaNumber = (e, setOnError) => {
     let isDataValid = false;
     let curval = Number(e.target.value);
     setTimeout(function () {
         // revisar si el valor de dias es mayor a 0 y menor a los dias establecidos en la ruta
         if (!isNaN(curval) && curval > 0) {
             isDataValid = true;
         }
         if (isDataValid) {
             console.log("valor numerico permitido");
             e.target.classList.remove("border-[1px]");
             e.target.classList.remove("border-red-600");
         } else {
             console.log(
                 "valor no permitido. La cantidad debe ser numerico y mayor a 0"
             );
             e.target.innerHTML = "";
             e.target.classList.add("border-[1px]");
             e.target.classList.add("border-red-600");
             // CustomToastify({
             //     title: "Cantidad incorrecta",
             //     message: "La cantidad debe ser mayor a 0",
             //     type: "warning"
             // });
         }
         setOnError(!isDataValid);
     }, 100);
 };

  const defaultColumn = {
    cell: ({ getValue, row, column: { id }, table }) => {
      return TableCell(getValue, row, id, table);
    }
  };

  const GetDataInsumos = async () => {
    setLoading(true);
    const data = await ObtenerInsumos();
    let cInsumos = [];
    if (data !== null && data.length > 0) {
      cInsumos = data.map((res) => {
        const insumo = {
          id: res.id,
          clave: res.clave,
          nombre: res.nombre,
          modelo: res.modelo,
          tipo: res.tipo,
          existencias: res.existen ?? "",
          unidad: res.unidad,
          cantidad: res.cantidad ?? 0
        };
        return insumo;
      });
    }
    setInsumos(cInsumos);
    setLoading(false);
  };

  async function GenerarManifiesto() {
    const folio = await ObtenerMaxSalida();
    let noManif = await ObtenerMaxManifiesto();
    if (noManif !== null) {
      if (noManif === "") noManif = 0;
      else noManif = Number(noManif.claveFinal);
    }
    //aqui debe existir una condicion de que si no se obtiene el max de salida, no se agregue
    if (folio.maxNumero || noManif >= 0) {
      const fecha = dayjs(props.datosViaje.fInicio).format("YYYY-MM-DD");
      const noSalida = Number(folio.maxNumero) + 1;
      let isNoManif =
        props.datosManif.servicio === "CB" ||
        props.datosManif.servicio === "CI" ||
        props.datosManif.servicio === "TI";
      if (isNoManif) noManif += 1;

      const noManifiesto = isNoManif ? noManif.toString() : props.datosManif.noManifiesto;

      insumos.forEach(async (ins) => {
        const cant = Number(ins.cantidad);
        //si se capturo un valor numerico y es mayor a 0
        if (!isNaN(cant) && cant > 0) {
          console.log(
            "se agregara la salida para ",
            ins.clave,
            "con folio ",
            noSalida
          );

          const bodyData = {
            numero: noSalida,
            fecha: fecha, //"2024-05-10",
            tipo: ins.tipo,
            insumo: ins.id,
            cantidad: cant,
            unidad: ins.unidad,
            referencia: props.ruta + props.datosManif.familia + ins.clave,
            familia: props.datosManif.familia,
            cliente: props.datosManif.cliente,
            manif: noManifiesto,
            factura: "",
            usom: "",
            status: "1",
            ruta: props.ruta,
          };
          await CrearSalida(bodyData);
          await ActualizarInsumo(ins.clave, {
            existen: ins.existencias - cant
          });
        }
      });
      //aqui si se crea la salida, se continua con la generacion del manifiesto
      const bodyGenManif = {
        clave: noManifiesto,
        tipo: "O",
        ruta: props.ruta,
        fruta: dayjs(props.datosViaje.fInicio, "YYYY-MM-DD"), //"2026-01-13T06:00:00.000Z",
        cliente: props.datosManif.cliente,
        fvisita: props.datosManif.fvisita, //"2006-05-04 00:00:00",
        orden: props.datosManif?.orden.toString(),
        salida: noSalida.toString(),
        status: "",
        fam: props.datosManif.familia,
        serv: props.datosManif.servicio,
        operador: props.datosViaje.oper,
        auxiliar: props.datosViaje.aux,
        vehiculo: props.datosViaje.veh,
        duracion: props.datosViaje.duracion,
        factura: "",
        planta: props.datosManif.planta,
        rutaplanta: props.datosManif.rutaplanta
      };
      await Generarmanifiesto(bodyGenManif);
      if (props.actionUpdateTable) {
        await props.actionUpdateTable(noSalida, noManifiesto);
      }
    } else {
      console.log("no se recupero el max de salidas o de manifietos");
      return false;
    }
    if (props.idModal) {
      const myModalEl = document.getElementById(props.idModal);
      Modal.getOrCreateInstance(myModalEl).hide();
    }
  }

  const { runAction, loadingAction } = useExecutePromise();
  const title = "Alta de Manifiesto";
  const pendingText = "Enviando manifiesto, por favor espere.";
  const successText = "Datos de manifiestos y Viaje agregados correctamente.";
  const errorText =
    "Ocurrió un error inesperado. Por favor reinténtalo más tarde.";

  async function onSubmitAgregarInsumo() {
    console.log("action agregar insumo");
    await runAction({
      promiseAction: GenerarManifiesto,
      title,
      pendingText,
      successText,
      errorText
    });
    return true;
  }

  /**
   * Accion para refrescar la tabla cada que se abre el modal en donde se encuentra cargada
   * y asi ver siempre los registros actualizados
   */
  useEffect(() => {
    const myModal = document.getElementById(props.idModal);
    initTE({ Modal });

    myModal.addEventListener("show.te.modal", () => {
      // do something...
      GetDataInsumos();
    });
  }, [props.idModal]);

  return (
    <>
      {(loading || loadingAction) && <CircleSpiner />}
      <div className="flex flex-col w-full gap-2">
        <CustomGenericTable
          columnsData={columnasData}
          data={insumos}
          optionsTable={opcionesTabla}
          setData={setInsumos}
          getData={GetDataInsumos}
          defaultColumn={defaultColumn}
        />
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end gap-2 rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
          <div className="w-full grid flex-row md:flex md:flex-row-reverse md:justify-start gap-4">
            {/* <span data-twe-modal-dismiss>
            </span> */}
            <button
              onClick={async () => await onSubmitAgregarInsumo()}
              type="button"
              className="btn-primary"
              disabled={insumos.length <= 0}
            >
              Agregar Insumo
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgregarInsumoForm;
