import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import logoPlantilla from "../../imagenes/logo_plantilla.png";

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
        fontFamily: 'Helvetica',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
    },
    headerContainer: {
        position: 'absolute',
        top: 20,
        left: 20,
        right: 20,
    },
    headerLeft: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    logo: {
        width: 50,
        height: 50,
        marginRight: 10,
    },

    content: {
        marginTop: 55,
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderColor: '#000',
        borderWidth: 1,
        margin: 5,
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableHeader: {
        flexDirection: 'row',
        backgroundColor: '#f0f0f0',
    },
    tableColHeader: {
        padding: 3,
        fontSize: 8,
        textAlign: 'center',
    },
    tableCol: {
        paddingHorizontal: 5,
        paddingVertical: 3,
        fontSize: 8,
    },
    lastCol: {
        borderRightWidth: 0,
    },
    tableCell: {
        margin: 'auto',
        fontSize: 8,
    },
    line: {
        width: '100%',
        height: 1,
        backgroundColor: 'black',
    },
    newTableColHeader: {
        borderColor: '#000',
        textAlign: 'center',
        fontSize: 8,
    },
    newTableCol: {
        borderColor: '#000',
        borderWidth: 1,
        padding: 3,
        width: 40,
        height: 15,
        fontSize: 8,
        alignContent: 'center',
    },
});

const RelacionRuta = ({ data }) => {
    const { manifiestos, ruta, fecha, fInicio, nombre, oper, aux } = data;

    // Calcula dinámicamente el número de registros por página
    const calcularRegistrosPorPagina = () => {
        // Aquí puedes implementar una lógica para calcular el número de registros
        // por página basado en el tamaño del contenido o cualquier otro criterio.
        // Por ejemplo, podrías basarlo en el tamaño de la fuente o el espacio disponible.
        return 13; // Ajusta este valor según sea necesario
    };

    const registrosPorPagina = calcularRegistrosPorPagina();
    const paginas = Array.from(
        { length: Math.ceil(manifiestos.length / registrosPorPagina) },
        (_, i) => manifiestos.slice(i * registrosPorPagina, (i + 1) * registrosPorPagina)
    );

    return (
        <Document>
            {paginas.map((pagina, pageIndex) => (
                pagina.length > 0 && (
                    <Page key={pageIndex} size="Letter" orientation="landscape" style={styles.page}>
                        <View style={styles.headerContainer} fixed>
                            <View style={styles.headerLeft}>
                                <Image style={styles.logo} src={logoPlantilla} />
                                <View>
                                    <Text>Sistema de Administración de Suvalsa</Text>
                                    <Text>Relacion de Manifiestos y Salidas para la Ruta: {ruta} - {nombre} del: {fecha} al {fInicio}</Text>
                                    <Text>Vehículo: {manifiestos[0]?.manifVehiculo} | Marca: {manifiestos[0]?.marca} | Placas: {manifiestos[0]?.placas}</Text>
                                    <Text>Operador: {oper} Auxiliar:{aux}</Text>
                                </View>
                            </View>
                            <View style={styles.line} />
                        </View>

                        <View style={styles.content}>
                            <View style={styles.table}>
                                <View style={[styles.tableRow, styles.tableHeader]}>
                                    <Text style={[{ flex: .12 }, styles.tableColHeader, styles.tableCell]}>Cliente</Text>
                                    <Text style={[{ flex: .45 }, styles.tableColHeader, styles.tableCell]}>Nombre</Text>
                                    <Text style={[{ flex: .20 }, styles.tableColHeader, styles.tableCell]}>Ciudad</Text>
                                    <Text style={[{ flex: .20 }, styles.tableColHeader, styles.tableCell]}>Salida</Text>
                                    <Text style={[{ flex: .20 }, styles.tableColHeader, styles.tableCell]}>Manifiesto</Text>
                                    <Text style={[{ flex: .09 }, styles.tableColHeader, styles.tableCell]}>Fecha</Text>
                                    <Text style={[{ flex: .20 }, styles.tableColHeader, styles.tableCell]}>Sangre</Text>
                                    <Text style={[{ flex: .35 }, styles.tableColHeader, styles.tableCell]}>Cultivos</Text>
                                    <Text style={[{ flex: .35 }, styles.tableColHeader, styles.tableCell]}>Patologicos</Text>
                                    <Text style={[{ flex: .35 }, styles.tableColHeader, styles.tableCell]}>No.Anat</Text>
                                    <Text style={[{ flex: .35 }, styles.tableColHeader, styles.tableCell]}>Punzo-Cortantes</Text>
                                    <Text style={[{ flex: .35 }, styles.tableColHeader, styles.tableCell]}>Medicinas Caducadas</Text>
                                    <Text style={[{ flex: .35 }, styles.tableColHeader, styles.tableCell]}>Total</Text>
                                    <Text style={[{ flex: .35 }, styles.tableColHeader, styles.tableCell]}>Recibio</Text>
                                </View>
                                {pagina.map((item, index) => (
                                    <View style={styles.tableRow} key={index}>
                                        <Text style={[{ flex: .12 }, styles.tableCol, styles.tableCell]}>{item.manifCliente}</Text>
                                        <Text style={[{ flex: .45 }, styles.tableCol, styles.tableCell]}>{item.clienteNombre}</Text>
                                        <Text style={[{ flex: .20 }, styles.tableCol, styles.tableCell]}>{item.clienteCiudad}</Text>
                                        <Text style={[{ flex: .20 }, styles.tableCol, styles.tableCell]}>{item.manifSalida}</Text>
                                        <Text style={[{ flex: .20 }, styles.tableCol, styles.tableCell]}>{item.manifClave}</Text>
                                        <Text style={[{ flex: .09 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .20 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                        <Text style={[{ flex: .35 }, styles.newTableCol, styles.tableCell]}></Text>
                                    </View>
                                ))}
                            </View>
                        </View>
                    </Page>
                )
            ))}
        </Document>
    );
};

export default RelacionRuta;