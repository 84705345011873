import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Input, initTE, Modal } from "tw-elements";
import CustomToastify from "../../../componentes/Toasts/CustomToast";
import { ObtenerAgentes, ObtenerVehiculos } from "../../../Apis/FetchCatalogos";
import {
  BuscarRutaClave,
  ObtenerClientesRuta,
  CrearClienteRuta,
  ActualizarClienteRuta,
  ActualizarRuta,
  EliminarClienteRutaById
} from "../../../Apis/FetchRutas";
import { createOption } from "../../../utils/CommonUtils";
import { useExecutePromise } from "../../../Hooks/UsePromiseAction";
import ReporteRuta from "../../../componentes/PDFServices/ReporteRuta";
import { pdf } from "@react-pdf/renderer"; // Importa la utilidad para generar PDFs


const useActualizarRuta = () => {
  initTE({ Input, Modal });
  //const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const [loadingData, setLoadingData] = useState(false);
  const [dataFounded, setDataFounded] = useState(false);
  const [agentes, setAgentes] = useState([]);
  const [vehiculos, setVehiculos] = useState([]);
  const [oper, setOper] = useState("");
  const [aux, setAux] = useState("");
  const [noEco, setNoEco] = useState("");
  const [noEco2, setNoEco2] = useState("");
  const [clientesRutaImprimir, setClientesRutaImprimir] = useState([]);

  const [loading, setLoading] = useState(false);
  const [rutaData, setRutaData] = useState(
    {
      clave: "",
      nombre: "",
      duracion: "",
      oper: "",
      aux: "",
      vehiculo1: "",
      id: ""
    }
  );
  const [clientesRuta, setClientesRuta] = useState([]);
  const [clientesRutaActuales, setClientesRutaActuales] = useState([]);

  const [rowSelection, setRowSelection] = useState({})
  const [dataRowSelection, setDataRowSelection] = useState({});

  const [sorting, setSorting] = useState([
    {
      id: "orden",
      asc: "true"
    },
  ]);

  const [newDataRows, setNewDataRows] = useState([])

  const msjErrorQuantity = 'La cantidad debe ser mayor a 0';

  const idModalRetirarCliente = "modalRetirarClientes";
  const idModalClientes = "modalClientes";

  const opcionesTabla = { ofText: "Clientes en Ruta de" };

  const [currentPage, setCurrentPage] = useState(0); // Agregar estado para la página actual
  const itemsPerPage = 35; // Definir cuántos elementos mostrar por página

  // Función para obtener los elementos de la página actual
  const paginatedClientesRuta = useMemo(() => {
    const start = currentPage * itemsPerPage;
    return clientesRuta.slice(start, start + itemsPerPage);
  }, [clientesRuta, currentPage]);

  const listenerValidaNumber = (e, setOnError) => {
    let isDataValid = false;
    let curval = Number(e.target.value);
    setTimeout(function () {
      // check if new value is more or equal to 255
      if (!isNaN(curval) && curval > 0) {
        isDataValid = true;
      }
      if (isDataValid) {
        e.target.classList.remove("border-[1px]");
        e.target.classList.remove("border-red-600");
      } else {
        e.target.innerHTML = "";
        e.target.classList.add("border-[1px]");
        e.target.classList.add("border-red-600");
      }
      setOnError(!isDataValid);
    }, 100);
  };

  const TableCell = (getValue, row, id, table) => {
    const [value, setValue] = useState("");
    const [onError, setOnError] = useState(false);
    let initialValue = getValue();

    const isOrden = id === "orden";
    // When the input is blurred, we'll call our table meta's updateData function
    const onBlur = () => {
      if (onError) return;
      //solo si cambia el valor del orden se hace la actualizacion del mismo en la tabla para reordenarlo
      if (row.original.orden !== value)
        actualizarOrdenCliente(table, { idxRow: row.index, ...row.original, orden: value }, setValue)
      //table.options.meta?.updateData(row.index, id, value);
    };
    //establecer el valor de inicio, cuando se recuperan del servicio
    useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);
    //dependiendo de la validacion se setea como input o no
    return isOrden ? (
      <>
        <input
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            listenerValidaNumber(e, setOnError);
          }}
          onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
          onBlur={onBlur}
          type={"number"}
          className={`${onError ? 'dataError' : ''}`}
        />
        {
          onError ?
            <span className="dataError-Msj">{msjErrorQuantity}</span>
            : ''
        }
      </>
    ) : (
      <span>{value}</span>
    );
  };

  const defaultColumn = {
    cell: ({ getValue, row, column: { id }, table }) => {
      return TableCell(getValue, row, id, table);
    }
  };

  const columnSize = 250;
  const columnas = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableColumnFilter: false
        //footer: props => props.column.id,
        //sortDescFirst: false, //sort by order in ascending order first (default is descending for number columns)
      },

      {
        accessorKey: "idClxruta",
        header: "IdClxruta",
        enableColumnFilter: false,
        size: 0,
      },
      {
        accessorKey: "orden",
        header: "Ord.",
        enableColumnFilter: false,
        enableSorting: true,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      },
      {
        accessorKey: "clave",
        header: "Clave",
        enableColumnFilter: true,
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
        //filterFn: 'customFilter', //using our custom function filter
        //filterFn: "fuseFilterFn"
        //footer: props => props.column.id,
        size: 50, //set column size for this column
        enableSorting: true
      },
      {
        accessorKey: "nombre",
        header: "Nombre",
        enableColumnFilter: false,
        size: columnSize, //set column size for this column
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "familia",
        header: "Familia",
        enableColumnFilter: false,
        enableSorting: false
        //footer: props => props.column.id,
      },
      {
        accessorKey: "ciudad",
        header: "Ciudad",
        enableColumnFilter: false,
        enableSorting: false,
        //footer: props => props.column.id,
        size: 100 //set column size for this column
      },
      {
        accessorKey: "servicio",
        header: "Servicio",
        enableColumnFilter: false,
        enableSorting: false,
        size: 100 //set column size for this column
        //footer: props => props.column.id,
      }
    ],
    []
  );

  const initValuesAc = {
    clave: "",
    nombre: "",
    duracion: "",
    oper: "",
    aux: "",
    noEco1: "",
    noEco2: "",
    operador: "",
    auxiliar: "",
    vehiculo: "",
    vehiculo2: ""
  };

  function resetRutaData() {
    setDataFounded(false)
    setRutaData(
      {
        clave: "",
        nombre: "",
        duracion: "",
        oper: "",
        aux: "",
        vehiculo1: "",
        id: ""
      }
    )
  }
  async function BuscarRuta(clave) {
    setLoadingData(true);
    const ruta = clave ? await BuscarRutaClave(clave) : null;
    let cRuta = [
      {
        clave: clave,
        nombre: "",
        duracion: "",
        oper: "",
        aux: "",
        vehiculo1: "",
        id: ""
      }
    ];

    if (ruta !== null && ruta.length > 0) {
      cRuta = ruta.map((r) => {
        const auxRuta = {
          clave: r.clave,
          nombre: r.nombre,
          duracion: r.duracion,
          oper: r.operador,
          aux: r.auxiliar,
          vehiculo1: r.vehiculo,
          id: r.id
        };
        setDataFounded(true);
        return auxRuta;
      });
    } else {
      setDataFounded(false);
    }
    setRutaData(...cRuta);
    setLoadingData(false);
    return cRuta[0];
  }

  async function loadClientesRuta(clave) {
    const clientesRuta = await ObtenerClientesRuta(clave);
    respaldarClientes(clientesRuta);

    let cRuta = [];
    if (clientesRuta) {
      cRuta = clientesRuta.map((r) => ({
        orden: r.orden,
        clave: r.cliente,
        nombre: r.nombre,
        familia: r.fam,
        ciudad: r.ciudad,
        servicio: r.serv,
        id: r.id,
        idClxruta: r.idClxruta
      }));
    }
    setClientesRuta(cRuta);
  }

  const respaldarClientes = (clientesR) => {
    let cRuta = [];
    if (clientesRuta) {
      cRuta = clientesR.map((cr) => ({
        orden: cr.orden,
        clave: cr.cliente,
        nombre: cr.nombre,
        familia: cr.fam,
        ciudad: cr.ciudad,
        servicio: cr.serv,
        id: cr.id,
        idClxruta: cr.idClxruta
      }));
    }
    setClientesRutaActuales(cRuta);
  };

  async function getDataFunction(clave) {
    setLoadingData(true);
    await loadClientesRuta(clave);
    setLoadingData(false);
  }

  // Función para agregar un arreglo de nuevos elementos manteniendo el orden ascendente por "orden"
  const AddData = (nuevosElementos) => {
    let updatedItems = [...clientesRuta];  // Copiar el arreglo original para evitar mutación directa
    nuevosElementos.forEach(nuevoElemento => {
      // Asignamos siempre el orden 0 al nuevo elemento
      nuevoElemento.orden = 0;
      nuevoElemento.idClxruta = nuevoElemento.customUUID
      // Insertamos el nuevo elemento al principio del arreglo (por defecto con orden 0)
      updatedItems = [nuevoElemento, ...updatedItems];
      // Reordenamos todos los elementos por el campo "orden"
      updatedItems = updatedItems.sort((a, b) => a.orden - b.orden);
    });

    //setClientesRuta(updatedItems);  // Actualizamos el estado con los elementos ajustados
    setNewDataRows(nuevosElementos)
  };

  // Función para actualizar el cliente con el nuevo orden y reajustar el arreglo
  const actualizarOrdenCliente = async (table, clienteEditado, setValue) => {
    let updatedItems = table.getRowModel().rows.map(row => row.original);//se copia el arreglo original para evitar mutación directa
    const { idxRow, idClxruta, orden: nuevoOrden } = clienteEditado;
    // Verificamos si el nuevo orden ya existe en el arreglo
    const existeOrden = updatedItems.find(item => Number(item.orden) === Number(nuevoOrden));
    if (existeOrden) {
      // Si el orden ya existe, necesitamos mover el cliente editado debajo del cliente con ese orden
      // Encontramos la posición donde insertar el nuevo cliente con el orden ajustado
      updatedItems = updatedItems.map(item => {
        if (Number(item.orden) > Number(nuevoOrden)) {
          return { ...item, orden: Number(item.orden) + 1 };  // Aumentamos el orden de los elementos posteriores
        }
        return item;
      });
      // Aseguramos que el cliente editado tenga el nuevo orden y lo insertamos en la posición correcta
      updatedItems = updatedItems.map(item =>
        item.idClxruta === idClxruta ? { ...item, orden: Number(nuevoOrden) + 1 } : item
      );

    } else {
      // Si el orden no existe, simplemente actualizamos el cliente editado con el nuevo orden
      updatedItems = updatedItems.map(item =>
        item.idClxruta === idClxruta ? { ...item, orden: Number(nuevoOrden) } : item
      );
    }
    // Reordenamos todos los elementos por el campo "orden", dependiendo si lleva o no id, se situa arriba yo abajo del orden existente
    const ordenedUpdatedItems = await reOrdenarLista([...updatedItems])
    table.options.meta?.updateData(
      null, null, null,
      ordenedUpdatedItems
    )
    setClientesRuta(ordenedUpdatedItems);
    //debido a que se movieron las filas por el reordenamiento, se debe setear el orden correcto en la tabla
    //de nuevo ya en el evento OnChange se cambia el valor y desde aqui no lo detecta la celda
    //debido a que se movio, se toma el valor con el indice de la actual celda editada para buscarlo en el arreglo nuevo
    setValue(ordenedUpdatedItems[idxRow].orden)
  }

  /**
   * Funcion que valida que no existan ordener con un valor <= a 0
   * @returns True si son validas las ordener, de lo contrario false
   */
  async function validaOrdenes() {
    return !clientesRuta.some(cr => cr.orden <= 0)
  }
  const { runAction, loadingAction } = useExecutePromise();
  const title = 'Actualización de Ruta'
  const pendingText = 'Enviando Datos de la Ruta y Clientes, por favor espere.'
  const successText = "Ruta Actualizada. Datos de la Ruta y Clientes agregados correctamente."
  const errorText = "Ocurrió un error inesperado. Por favor reinténtalo más tarde."

  async function onSubmitActualizaRuta(values, { resetForm }) {
    if (await validaOrdenes()) {
      await runAction({
        promiseAction: async () => {
          const datosActRuta = {
            id: rutaData.id,
            clave: values.clave,
            nombre: values.nombre,
            duracion: values.duracion,
            operador: values.oper,
            auxiliar: values.aux,
            vehiculo: values.noEco1
            //noEco2: values.noEco2
          };
          await ActualizarRutaCliente(datosActRuta);
          await ProcesarClienteRuta(values.clave);
          //await resetForm();
          await BuscarRuta(values.clave);
        }, title, pendingText, successText, errorText
      })
    } else {
      CustomToastify({
        title: "Datos del cliente incorrectos", message: "Por favor revisa que todos los clientes cuenten con un orden correcto.", type: 'warning'
      })
    }
  }
  async function ProcesarClienteRuta(claveRuta) {

    //marcamos el flag para senanal que se requiere o no que actualice los datos, esto en caso de que si se actualizaara algun dato unicamente en ese caso se recarga la tabla
    let requiredUpdateTable = false;
    if (clientesRuta && clientesRutaActuales && claveRuta) {
      let auxClientes = [...clientesRutaActuales]; // Copia del arreglo original

      if (clientesRutaActuales.length > 0) {
        // Recorremos los clientes de la lista original (clientesRutaActuales)
        clientesRutaActuales.forEach(async (clienteActual) => {
          // Validamos si el cliente ya no existe en la lista actualizada
          const clienteEliminado = clientesRuta.find(
            (c) => c.idClxruta === clienteActual.idClxruta // Comparamos por idClxruta
          );

          // Si el cliente no existe en la lista actualizada, lo eliminamos
          if (!clienteEliminado) {
            // Eliminamos el cliente
            requiredUpdateTable = true
            await EliminarClienteRutaById(clienteActual.idClxruta)
            //console.log('se elimina cliente', clienteActual.idClxruta, clienteActual.clave)
            // Filtramos de la lista auxiliar
            auxClientes = auxClientes.filter(
              (auxC) => auxC.idClxruta !== clienteActual.idClxruta
            );
          }
        });
      }


      // Procesamos cada cliente de la lista actualizada (clientesRuta)
      for (const clRuta of clientesRuta) {
        const dataClienteRuta = {
          clave: claveRuta,
          orden: clRuta.orden,
          cliente: clRuta.clave, // Usamos idClxruta para identificar al cliente
        };
        if (auxClientes.length > 0) {
          // Verificamos si el cliente ya existe en la lista original (clientesRutaActuales)
          const existeCliente = clRuta.idClxruta ? auxClientes.find(
            (c) => c.idClxruta === clRuta.idClxruta
          ) : undefined;
          if (existeCliente) {
            // Si el cliente existe, verificamos si el orden ha cambiado
            if (existeCliente.orden !== clRuta.orden) {
              // Si el orden ha cambiado, se procede a actualizar
              //console.log('se Actualiza: ', existeCliente.idClxruta, dataClienteRuta)
              requiredUpdateTable = true
              await ActualizarOrdenClienteRuta(existeCliente.idClxruta, dataClienteRuta); // Actualizamos el cliente
            }
          } else {
            // Si el cliente no existe, lo agregamos como nuevo
            //console.log('se agrega nuevo:, ',dataClienteRuta.cliente)
            requiredUpdateTable = true
            await CrearClienteRuta(dataClienteRuta); // Agregamos un nuevo cliente
          }
        } else {
          // Si no existen clientes en la lista original, agregamos todos los clientes de clientesRuta como nuevos
          //console.log('Se agrega porque todos los clientes son nuevos:',dataClienteRuta.cliente)
          requiredUpdateTable = true
          await CrearClienteRuta(dataClienteRuta); // Agregamos un nuevo cliente
        }
      }
    }

    if (requiredUpdateTable) {
      getDataFunction(claveRuta)
    }
  }

  async function ActualizarRutaCliente(data) {
    await ActualizarRuta(rutaData.id, data);
  }

  async function ActualizarOrdenClienteRuta(id, ruta) {
    await ActualizarClienteRuta(id, ruta);
  }

  async function actionImprimir(clave) {
    try {
      // Obtener datos de los clientes y de la ruta
      const clientesRutaImprimir = await ObtenerClientesRuta(clave); // Datos de los clientes
      const NombreRuta = await BuscarRuta(clave); // Datos de la ruta

      // Verifica que ambos datos estén disponibles
      if (!clientesRutaImprimir || !NombreRuta) {
        console.error("Error: No se pudieron obtener datos para generar el reporte.");
        return;
      }

      // Imprime los datos en la consola para verificar
      console.log("Datos de la ruta:", NombreRuta);
      console.log("Datos de los clientes:", clientesRutaImprimir);

      // Generar el PDF
      const pdfInstance = pdf(
        <ReporteRuta NombreRuta={NombreRuta} datosReporte={clientesRutaImprimir} />
      );

      const blob = await pdfInstance.toBlob(); // Generar el blob del PDF

      // Crear enlace de descarga
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Reporte_Ruta_${NombreRuta.clave}.pdf`; // Personaliza el nombre del archivo
      document.body.appendChild(link);
      link.click(); // Simular clic para descargar el archivo
      document.body.removeChild(link); // Remover enlace temporal
      URL.revokeObjectURL(link.href); // Liberar memoria del blob

      console.log("Reporte descargado correctamente.");

    } catch (error) {
      console.error("Error al generar el reporte:", error);
    }
  }




  function actionAgregar() {
    const myModal = new Modal(document.getElementById(idModalClientes));
    myModal.show();
  }

  function actionRetirar() {
    const myModal = new Modal(document.getElementById(idModalRetirarCliente));
    myModal.show();
  }

  async function reOrdenarLista(tmpClientesRuta) {
    tmpClientesRuta.sort(function (a, b) {
      if (a.orden > b.orden) {
        return 1;
      }
      if (a.orden < b.orden) {
        return -1;
      }
      // a must be equal to b,
      return 0
    });
    let noOrden = 1;
    const newList = tmpClientesRuta.map((clRuta) => {
      //solo si el orden es valido, se toma en cuenta para actualizar el orden, si no, se mantiene tal cual
      if (clRuta.orden > 0) {
        clRuta.orden = noOrden;
        noOrden++;
      }
      return clRuta;
    });

    return newList
  }

  async function ordenarLista() {
    const tmpClientesRuta = [...clientesRuta];
    tmpClientesRuta.sort(function (a, b) {
      if (a.orden > b.orden) {
        return 1;
      }
      if (a.orden < b.orden) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    let noOrden = 1;
    tmpClientesRuta.map((clRuta) => {
      clRuta.orden = noOrden;
      noOrden++;
      return clRuta;
    });
    setClientesRuta(tmpClientesRuta);
    //se deselecciona la tabla
    setRowSelection({});
  }

  async function confirmarEliminacion() {
    setLoading(true);
    const idxRowSel = Object.keys(rowSelection)
    setClientesRuta(
      clientesRuta.filter((row, index) => {
        if (dataRowSelection[0].idClxruta) {
          if (row.idClxruta !== dataRowSelection[0].idClxruta) return row;
        } else
          if (index !== Number(idxRowSel[0])) return row;
      })
    );

    CustomToastify({
      title: "Cliente retirado", message: `Cliente ${getDataSelected()?.clave ?? ''} fue retirado de la lista correctamente`, type: 'success'
    })
    //se deselecciona la tabla
    setRowSelection({});
    setLoading(false);
  }

  const buttonsGroup = {
    buttonPrimary: {
      disabled: loading,
      type: "submit",
      label: "Grabar"
    },
    buttonSecondary: (clave) => ({
      action: async () => {
        setLoadingData(true);
        await actionImprimir(clave);
        setLoadingData(false);

      },
      type: "button",
      label: "Imprimir Reporte"
    }),
    buttonTertiary: {
      action: actionAgregar,
      disabled: loading || rutaData?.id === "",
      type: "button",
      label: "Agregar Cliente"
    },
    buttonCuatriary: {
      action: actionRetirar,
      disabled: loading || (!clientesRuta.length || !dataRowSelection.length),
      type: "button",
      label: "Retirar Cliente"
    },
    buttonQuintuary: {
      action: ordenarLista,
      disabled: loading || clientesRuta.length <= 0,
      type: "button",
      label: "Ordenar"
    }
  };

  function getDataSelected() {
    return dataRowSelection[0]
  }

  function resetStates() {
    setOper("");
    setAux("");
    setNoEco("");
    setNoEco2("");
    setClientesRuta([]);
  }

  const onChangeOper = useCallback((e) => {
    const valueSel = e.target.value.trim();
    const selected = agentes.find((op) => op.clave === valueSel);
    if (selected) setOper(selected.nombre);
    else setOper("")
  }, [agentes]);

  const onChangeAux = useCallback((e) => {
    const valueSelAux = e.target.value.trim();
    const selected = agentes.find((op) => op.clave === valueSelAux);
    if (selected) setAux(selected.nombre);
    else setAux("")
  }, [agentes]);

  const onChangeNoEco = useCallback((e) => {
    const valueSelEco = e.target.value.trim();
    const selected = vehiculos.find(
      (op) => op.CLAVE.trim() === valueSelEco.trim()
    );
    if (selected) setNoEco(selected.MARCA + " " + selected.MODELO);
    else setNoEco("");
  }, [vehiculos]);

  const onChangeNoEco2 = useCallback((e) => {
    const valueSelEco = e.target.value.trim();
    const selected = vehiculos.find(
      (op) => op.CLAVE.trim() === valueSelEco.trim()
    );
    if (selected) setNoEco2(selected.MARCA + " " + selected.MODELO);
    else setNoEco2("");
  }, [vehiculos]);

  const agregarSelectAg = (puesto, defaultValue) => {
    if (agentes === null) {
      return "";
    }
    const ags = agentes.filter((ags) => ags.puesto === puesto);

    let currentAg = [];
    if (defaultValue) {
      const agsCurrent = agentes.filter(
        (ags) => ags.puesto === puesto && ags.clave === defaultValue
      );
      if (agsCurrent.length === 0)
        currentAg.push(createOption(defaultValue, defaultValue, defaultValue));
    }

    ags.forEach((ag) =>
      currentAg.push(createOption(ag.id, ag.clave, ag.clave))
    );

    return currentAg;
    // return ags.map((ag) =>
    //   createOption(ag.id, ag.clave, ag.clave)
    // );
  };

  const agregarSelectNoEco = () => {
    if (vehiculos === null) {
      return "";
    }

    return vehiculos.map((veh) => createOption(veh.id, veh.CLAVE, veh.CLAVE));
  };

  useEffect(() => {
    if (agentes !== null && agentes.length > 0) {
      const mySelect = document.getElementById("selectOper");
      mySelect?.addEventListener("valueChange.te.select", (e) => {
        onChangeOper(e);
      });
      const mySelectAux = document.getElementById("selectAux");
      mySelectAux?.addEventListener("valueChange.te.select", (e) => {
        onChangeAux(e);
      });
    }
  }, [agentes, onChangeAux, onChangeOper]);

  useEffect(() => {

    if (vehiculos !== null && vehiculos.length > 0) {
      //noEco 1
      const mySelectV = document.getElementById("selectVehic1");
      mySelectV?.addEventListener("valueChange.te.select", (e) => {
        onChangeNoEco(e);
      });
      //noEco 2
      const mySelectV2 = document.getElementById("selectVehic2");
      mySelectV2?.addEventListener("valueChange.te.select", (e) => {
        onChangeNoEco2(e);
      });
    }
  }, [vehiculos, onChangeNoEco, onChangeNoEco2]);

  useEffect(() => {
    initTE({ Modal });

    async function listarVehiculos() {
      setLoadingData(true);
      const listVehic = await ObtenerVehiculos();
      setVehiculos(listVehic);
      setLoadingData(false);
    }

    async function listarAgentes() {
      setLoadingData(true);
      const listaAgent = await ObtenerAgentes();
      setAgentes(listaAgent);
      setLoadingData(false);
    }

    const consultarTodo = async () => {
      const inicio = performance.now()
      await Promise.all([listarAgentes(), listarVehiculos()])
      const fin = performance.now()
      console.log(fin - inicio)
    }

    consultarTodo();

  }, []);

  useEffect(() => {
    if (dataFounded) {
      const eOper = {
        target: {
          value: rutaData?.oper ?? ""
        }
      };
      onChangeOper(eOper);

      const eAux = {
        target: {
          value: rutaData?.aux ?? ""
        }
      };
      onChangeAux(eAux);

      const e = {
        target: {
          value: rutaData?.vehiculo1 ?? ""
        }
      };
      onChangeNoEco(e);
    } else {
      resetStates();
    }
  }, [dataFounded, rutaData, onChangeAux, onChangeNoEco, onChangeOper]);

  return {
    dataFounded,
    loading,
    loadingData,
    loadingAction,
    AddData,
    newDataRows,
    rutaData,
    resetRutaData,
    BuscarRuta,
    opcionesTabla,
    columnas,
    defaultColumn,
    clientesRuta,
    setClientesRuta,
    sorting,
    setSorting,
    rowSelection,
    setRowSelection,
    setDataRowSelection,
    getDataSelected,
    initValuesAc,
    onSubmitActualizaRuta,
    idModalClientes,
    idModalRetirarCliente,
    agregarSelectAg,
    agregarSelectNoEco,
    noEco,
    noEco2,
    aux,
    oper,
    getDataFunction,
    confirmarEliminacion,
    buttonsGroup
  };
};

export default useActualizarRuta;
