// ActualizarClienteForm.jsx
import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form } from "formik";
import { Select } from "tw-elements";
import Accordion from "../../../../componentes/Accordion/Accordion";
import { InputText, Select as SelectF } from "../../../../componentes/Formik";
import { BuscarClientePorClave, ActualizarCliente } from "../../../../Apis/FetchClientes";
import schemaActualizarCliente from "./Schemas/schemaActualizarCliente";
import { useExecutePromise } from "../../../../Hooks/UsePromiseAction";
import CircleSpiner from "../../../../componentes/Spinners/CircleSpiner";
import useServicios from "../../../../Hooks/UseServicios";
import useFamilias from "../../../../Hooks/UseFamilias";
import { agSelectOptRutaPlanta } from "../Alta/AltaCliente";
import { ObtenerRutasPorPlanta } from "../../../../Apis/FetchCatalogos";
import { InputTextForm } from "../../../../componentes/Formik/InputText";
import { parseNullToEmpty } from "../../../../utils/CommonUtils";

const ActualizarClienteForm = () => {
    const { runAction, loadingAction } = useExecutePromise();
    const [loadingData, setLoadingData] = useState(false);
    const [notFoundedData, setNotFoundedData] = useState(true);
    const [rutasP, setRutasP] = useState([]);

    const { servs, agSelectOptServ } = useServicios("selectServ");
    const { familias, agSelectOptFam } = useFamilias("selectFam");

    // Valores iniciales del formulario
    const initialValues = {
        clave: '',
        unidadnegocio: '',
        fnombre: '',
        tiposociedad: '',
        regimenfiscal: '',
        usocfdi: '',
        correoelectronico: '',
        segundocorreoelectronico: '',
        ruta: '',
        orden: '',
        aalta: '',
        condspago: '',
        rfc: '',
        revision: '',
        pago: '',
        status: '',
        registro: '',
        contratos: '',
        conts: '',
        paqinsm: '',
        rutaplanta: "",
        replegal: '',
        nombre: '',
        numerocta: '',
        mtdopago: '',
        calle: '',
        colonia: '',
        ciudad: '',
        estado: '',
        cp: '',
        entreqcalles: '',
        telefono: '',
        servicio: '',
        contacto: '',
        refbanco: '',
        calleFacturacion: '',
        coloniaFacturacion: '',
        ciudadFacturacion: '',
        estadoFacturacion: '',
        cpFacturacion: '',
        telefonoFacturacion: '',
        contactoFacturacion: '',
        frec: '',
        fam: ''
    };

    // useEffect para inicializar selects y cargar rutas
    useEffect(() => {
        const initializeSelects = () => {
            const selectStatus = document.querySelector("#selectStatus");
            if (selectStatus) Select.getOrCreateInstance(selectStatus);

            const selectRutaPlanta = document.querySelector("#selectRutaPlanta");
            if (selectRutaPlanta) Select.getOrCreateInstance(selectRutaPlanta);
        };

        const listarRutasP = async () => {
            setLoadingData(true);
            try {
                const listaRutasP = await ObtenerRutasPorPlanta();
                setRutasP(listaRutasP);
            } catch (error) {
                console.error("Error fetching rutasP:", error);
            } finally {
                setLoadingData(false);
            }
        };

        initializeSelects();

        // Carga de rutas si no están en el estado
        if (rutasP.length === 0) {
            listarRutasP();
        }
    }, [rutasP.length]);

    /**
     * handleSearch: busca un cliente por clave y setea el formulario
     */
    const handleSearch = useCallback(
        async (clave, resetForm, setTouched) => {
            setNotFoundedData(true);
            if (!clave) {
                resetForm({ values: initialValues });
                setTouched({ clave: true });
                return;
            }
            setLoadingData(true);
            try {
                const response = await BuscarClientePorClave(clave);
                if (response && response.length !== 0) {
                    // Buscar la rutaPlanta en la lista de rutas
                    const rutaPSeleccionada = rutasP.find(
                        (r) =>
                            r.rutaplanta?.trim().toUpperCase() === (response.rutaplanta || "").trim().toUpperCase() &&
                            r.planta === response.planta
                    );

                    // Manejo de la familia
                    const familiaSeleccionada = familias.find((f) => f.clave === response.fam);

                    const mergedValues = {
                        // Campos que vienen de la respuesta
                        unidadnegocio: response.unidadnegocio,
                        tiposociedad: response.tiposociedad,
                        regimenfiscal: response.regimenfiscal,
                        usocfdi: response.usocfdi,
                        correoelectronico: response.correoelectronico,
                        segundocorreoelectronico: response.segundocorreoelectronico,
                        ruta: response.ruta,
                        orden: response.orden,
                        aalta: response.aalta,
                        condspago: response.condspago,
                        rfc: response.rfc,
                        revision: response.revision,
                        pago: response.pago,
                        status: response.status || 'A', // Valor por defecto si está vacío
                        registro: response.registro,
                        contratos: response.contratos,
                        conts: response.conts,
                        paqinsm: response.paqinsm,
                        replegal: response.replegal,
                        nombre: response.nombre,
                        mtdopago: response.mtdopago,
                        calle: response.calle,
                        colonia: response.colonia,
                        ciudad: response.ciudad,
                        estado: response.estado,
                        cp: response.cp,
                        entreqcalles: response.entreqcalles,
                        telefono: response.telefono,
                        servicio: response.servicio,
                        contacto: response.contacto,
                        rutaplanta:
                            rutaPSeleccionada
                                ? rutaPSeleccionada.planta + "&&" + rutaPSeleccionada.rutaplanta
                                : (response.planta ? response.planta : "") +
                                "&&" +
                                (response.rutaplanta ? response.rutaplanta : ""),
                        fam: familiaSeleccionada ? familiaSeleccionada.clave : "",
                        numerocta: response.numerocta,
                        fnombre: response.fnombre,
                        calleFacturacion: response.fcalle,
                        coloniaFacturacion: response.fcolonia,
                        ciudadFacturacion: response.fciudad,
                        estadoFacturacion: response.festado,
                        cpFacturacion: response.fcp,
                        refbanco: response.refbanco,
                        telefonoFacturacion: response.ftelefono,
                        contactoFacturacion: response.fcontacto,
                        frec: response.frec,
                        clave
                    };

                    // Convierte valores null a "" para evitar warnings en inputs
                    const safeResponse = parseNullToEmpty(mergedValues);

                    // Seteamos el formulario
                    resetForm({ values: safeResponse });
                    setNotFoundedData(false);
                } else {
                    // Si no hay resultados, reseteamos todo (pero mantenemos clave)
                    resetForm({ values: { ...initialValues, clave } });
                    setNotFoundedData(true);
                }
            } catch (error) {
                console.error("Error during handleSearch:", error);
            } finally {
                setLoadingData(false);
            }
        },
        [familias, rutasP]
    );

    /**
     * onSubmitAction: maneja el envío del formulario para actualizar el cliente
     */
    const onSubmitAction = useCallback(
        async (values, { resetForm }) => {
            const [planta, rutaplanta] = values.rutaplanta.split("&&").map((val) => val.trim());
            const updatedValues = {
                unidadnegocio: values.unidadnegocio,
                fnombre: values.fnombre,
                tiposociedad: values.tiposociedad,
                regimenfiscal: values.regimenfiscal,
                usocfdi: values.usocfdi,
                correoelectronico: values.correoelectronico,
                segundocorreoelectronico: values.segundocorreoelectronico,
                ruta: values.ruta,
                orden: values.orden,
                aalta: values.aalta,
                condspago: values.condspago,
                rfc: values.rfc,
                revision: values.revision,
                pago: values.pago,
                status: values.status,
                registro: values.registro,
                conts: values.conts ? parseInt(values.conts, 10) : null,
                paqinsm: values.paqinsm,
                planta: planta || "",
                rutaplanta: rutaplanta || "",
                replegal: values.replegal,
                nombre: values.nombre,
                numerocta: values.numerocta,
                calle: values.calle,
                colonia: values.colonia,
                ciudad: values.ciudad,
                estado: values.estado,
                cp: values.cp,
                entreqcalles: values.entreqcalles,
                telefono: values.telefono,
                serv: values.servicio,
                contacto: values.contacto,
                refbanco: values.refbanco,
                mtdopago: values.mtdopago,
                fcalle: values.calleFacturacion,
                fcolonia: values.coloniaFacturacion,
                fciudad: values.ciudadFacturacion,
                festado: values.estadoFacturacion,
                fcp: values.cpFacturacion,
                ftelefono: values.telefonoFacturacion,
                fcontacto: values.contactoFacturacion,
                frec: values.frec,
                fam: values.fam
            };

            try {
                // Usamos el hook runAction para manejar la promesa
                await runAction({
                    promiseAction: async () => {
                        const response = await ActualizarCliente(values.clave, updatedValues);
                        console.log("Respuesta de la API:", response.responseModificar.resp.clave);

                        const claveRecibida = response.responseModificar.resp.clave;
                        if (claveRecibida === values.clave) {
                            console.log("Datos actualizados correctamente:", response);
                        } else {
                            console.error("Error: La clave no coincide:", {
                                enviado: values.clave,
                                recibido: claveRecibida
                            });
                            throw new Error("Error: La clave actualizada no coincide con la enviada.");
                        }
                    },
                    title: "Actualización de Cliente",
                    pendingText: "Actualizando cliente, por favor espere...",
                    successText: "Cliente actualizado correctamente.",
                    errorText: "Ocurrió un error al actualizar el cliente. Inténtalo nuevamente."
                });

                // Si deseas limpiar el formulario en caso de éxito, puedes:
                // resetForm({ values: initialValues });
            } catch (error) {
                console.error("Error al actualizar:", error);
            } finally {
                // Lógica final que necesites ejecutar
            }
        },
        [runAction]
    );

    // Render principal del componente
    return (
        <>
            {/* Muestra spinner si estamos cargando datos o acción */}
            {(loadingData || loadingAction) && <CircleSpiner />}

            <Formik
                initialValues={initialValues}
                validationSchema={schemaActualizarCliente}
                onSubmit={onSubmitAction}
            >
                {({ setTouched, resetForm, values }) => (
                    <Form
                        className="flex flex-col w-full p-2 gap-2"
                        onKeyDown={(e) => {
                            // Evita que la tecla Enter envíe el formulario
                            if (e.key === "Enter") {
                                e.preventDefault();
                            }
                        }}
                    >
                        {/* Sección Datos del Cliente */}
                        <Accordion titulo="Datos del Cliente" idTarget="datosCliente" expanded={true}>
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                {/* Clave: con búsqueda onBlur y onKeyDown */}
                                <InputTextForm
                                    label="Clave"
                                    name="clave"
                                    type="text"
                                    placeholder=""
                                    onBlurAction={() => handleSearch(values.clave, resetForm, setTouched)}
                                    onKeyDownAction={() => handleSearch(values.clave, resetForm, setTouched)}
                                />
                                <button
                                    disabled={!values.clave}
                                    type="button"
                                    className="btn-secondary mt-2 w-full mx-auto md:mx-0 center md:justify-end"
                                    onClick={async () => {
                                        await handleSearch(values.clave, resetForm, setTouched);
                                    }}
                                >
                                    Buscar Cliente
                                </button>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-2">
                                <div className="md:col-span-2">
                                    <InputText
                                        label="Nombre del Cliente"
                                        name="nombre"
                                        type="text"
                                        placeholder=""
                                        disabled={notFoundedData}
                                    />
                                </div>

                                <InputText
                                    label="Unidad de Negocio"
                                    name="unidadnegocio"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />

                                <InputText
                                    label="Tipo de Sociedad"
                                    name="tiposociedad"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />

                                <InputText
                                    label="Correo Electrónico"
                                    name="correoelectronico"
                                    type="email"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Correo Electrónico Secundario"
                                    name="segundocorreoelectronico"
                                    type="email"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Ruta"
                                    name="ruta"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Orden"
                                    name="orden"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Año de Alta"
                                    name="aalta"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    disabled={true}
                                    label="Frecuencia"
                                    name="frec"
                                    type="text"
                                    placeholder=""
                                />

                                <InputText
                                    label="Condición de Pago"
                                    name="condspago"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="RFC"
                                    name="rfc"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Revisión de Facturas"
                                    name="revision"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Pago de Facturas"
                                    name="pago"
                                    type="text"
                                    disabled={notFoundedData}
                                />

                                {/* Select de Status */}
                                <SelectF
                                    id="selectStatus"
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    name="status"
                                    disabled={notFoundedData}
                                    label="Status"
                                >
                                    <option value="I">SUSPENDIDO</option>
                                    <option value="A">ACTIVO</option>
                                    <option value="B">BAJA DEFINITIVA</option>
                                </SelectF>

                                <InputText
                                    label="Registro INE"
                                    name="registro"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Contratos"
                                    name="contratos"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="No. Consultorios"
                                    name="conts"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Paquete de Insumos"
                                    name="paqinsm"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />

                                {/* Select de familia */}
                                <SelectF
                                    id="selectFam"
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    label="Familia"
                                    name="fam"
                                    value={values.fam}
                                    disabled={notFoundedData}
                                >
                                    <option hidden defaultValue value="">
                                        Selecciona una Familia
                                    </option>
                                    {agSelectOptFam()}
                                </SelectF>

                                {/* Select de RutaPlanta */}
                                <SelectF
                                    id="selectRutaPlanta"
                                    disabled={notFoundedData}
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    label="Ruta Planta"
                                    name="rutaplanta"
                                    value={values.rutaplanta}
                                >
                                    <option hidden defaultValue value="">
                                        Selecciona Ruta/Planta
                                    </option>
                                    {agSelectOptRutaPlanta(rutasP)}
                                </SelectF>
                            </div>
                        </Accordion>

                        {/* Sección Dirección para Recolección */}
                        <Accordion
                            titulo="Dirección para Recolección"
                            idTarget="direccionRecoleccion"
                            expanded={true}
                        >
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-2">
                                {/* Select de Servicios */}
                                <SelectF
                                    id="selectServ"
                                    className="pt-[0.1235rem]"
                                    data-te-select-init
                                    label="Servicio"
                                    name="servicio"
                                    disabled={notFoundedData}
                                >
                                    <option hidden defaultValue value="">
                                        Selecciona un servicio
                                    </option>
                                    {agSelectOptServ(servs)}
                                </SelectF>

                                <InputText
                                    label="Ref. Bancaria"
                                    name="refbanco"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <div className="md:col-span-2">
                                    <InputText
                                        label="Calle"
                                        name="calle"
                                        type="text"
                                        placeholder=""
                                        disabled={notFoundedData}
                                    />
                                </div>
                                <InputText
                                    label="Colonia"
                                    name="colonia"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Ciudad"
                                    name="ciudad"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Estado"
                                    name="estado"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="C.P."
                                    name="cp"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Entre Calles"
                                    name="entreqcalles"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Teléfono"
                                    name="telefono"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Contacto"
                                    name="contacto"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                            </div>
                        </Accordion>

                        {/* Sección Dirección para Facturación */}
                        <Accordion
                            titulo="Dirección para Facturación"
                            idTarget="direccionFacturacion"
                            expanded={true}
                        >
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-2">
                                <div className="md:col-span-2">
                                    <InputText
                                        label="Nombre/Razon Social"
                                        name="fnombre"
                                        type="text"
                                        placeholder=""
                                        disabled={notFoundedData}
                                    />
                                </div>
                                <InputText
                                    label="No. Cuenta"
                                    name="numerocta"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Método de Pago"
                                    name="mtdopago"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Regimen Fiscal"
                                    name="regimenfiscal"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Uso de CFDI"
                                    name="usocfdi"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Calle de Facturación"
                                    name="calleFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Colonia de Facturación"
                                    name="coloniaFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Ciudad de Facturación"
                                    name="ciudadFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Estado de Facturación"
                                    name="estadoFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="C.P. de Facturación"
                                    name="cpFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Teléfono de Facturación"
                                    name="telefonoFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                                <InputText
                                    label="Contacto de Facturación"
                                    name="contactoFacturacion"
                                    type="text"
                                    placeholder=""
                                    disabled={notFoundedData}
                                />
                            </div>
                        </Accordion>

                        {/* Botón de envío */}
                        <div className="flex flex-col items-center justify-center">
                            <button
                                className="btn-primary mt-4 w-full md:w-1/6 mx-auto md:mx-0 center md:justify-end"
                                type="submit"
                                disabled={loadingData || loadingAction}
                            >
                                Actualizar Cliente
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ActualizarClienteForm;